import post from "@/util/post";
import get from "@/util/get";
import patch from "@/util/patch";
import del from "@/util/delete";

export default {
    async loadTasks({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/projects/tasks?projectName=${data}`);
        commit('setTasks', responseData.responseData);
    },
    async addTask({commit}, data) {
        data.tuid = await post(`${process.env.VUE_APP_URL}/company/projects/tasks`, JSON.stringify(data));
        commit('addTask', data);
    },
    async deleteTask({commit}, data) {
        await del(`${process.env.VUE_APP_URL}/company/projects/tasks?puid=${data.puid}&tuid=${data.tuid}`);
        commit('deleteTask', data);
    },
    async updateTask({commit}, data) {
        await patch(`${process.env.VUE_APP_URL}/company/projects/tasks`, JSON.stringify(data));
        commit('updateTask', data);
    },
}