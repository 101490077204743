import requestHeaders from "@/util/requestHeaders";
import manageAuthorizationToken from "@/util/manage-authorization-token";
import authHeader from "@/util/auth-header";
import {getResponseData, manageResponseException} from "@/util/response-manager";

export default async function patch(url, body) {
    try {
        return await sendRequest(url, body);
    } catch (error) {
        return await manageResponseException(error, () => sendRequest(url, body));
    }

}

const sendRequest = async (url, body) => {
    const response = await fetch(url, {
        method: 'PATCH',
        headers: {
            ...requestHeaders,
            ...authHeader(),
            'Content-Type': 'application/json'
        },
        body
    });

    manageAuthorizationToken(response);

    return await getResponseData(response);
}