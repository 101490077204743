export default {
    companies(state){
        return state.companies
    },
    hasCompanies(state){
        return state.companies && state.companies.length >0;
    },
    doCompanyExists(state){
        return !!state.isCompanyNameExists;
    },
    doCompanyEmailExists(state){
        return !!state.isEmailExists
    },
    doCompanyPhoneExists(state){
        return !!state.isPhoneExists
    },
    doUserPhoneExists(state){
        return !!state.isUserPhoneExists;
    },
    doUserEmailExists(state){
        return !!state.isUserEmailExists;
    }
}