import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
    namespaced: true,
    state(){
        return {
            settings: null,
            theme: localStorage.getItem("theme") || 'light',
            //Updates
            usersAllowedAddUpdates: [],
            usersAllowedEditUpdates: [],
            usersAllowedDeleteUpdates: [],
            //Events
            usersAllowedAddEvents: [],
            usersAllowedEditEvents: [],
            usersAllowedDeleteEvents: [],
            //TimeSheets
            usersAllowedToSeeAllCompanyEmployeesTimesheets: [],
            usersAllowedToApproveAllCompanyEmployeesTimeSheets: [],
            usersAllowedToRejectAllCompanyEmployeesTimeSheets: [],
            usersAllowedToModifyAllCompanyEmployeesTimeSheets: [],
            //Administrative
            usersAllowedAddAdministrative: [],
            usersAllowedEditAdministrative: [],
            usersAllowedDeleteAdministrative: [],

            usersResponsibleForNewEmployeesApproval: [],

            usersAllowedToAssignManager: [],
            usersAllowedToRemoveManagerAssignment: [],
            //Employees
            usersAllowedEditAllCompanyEmployeesInfo: [],
            usersAllowedDeleteCompanyEmployees: [],
        }
    },
    mutations,
    getters,
    actions
}