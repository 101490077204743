import requestHeaders from "@/util/requestHeaders";
import manageAuthorizationToken from "@/util/manage-authorization-token";
import authHeader from "@/util/auth-header";
import {getResponseData, manageResponseException} from "@/util/response-manager";

export default async function get(url) {
    try {
        return await sendRequest(url);
    } catch (error) {
        return await manageResponseException(error, () => sendRequest(url));
    }

}

const sendRequest = async (url) => {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...requestHeaders,
                ...authHeader()
            }
        });

        manageAuthorizationToken(response);

        return await getResponseData(response);
}