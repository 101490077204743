export default {
    setAccessGroups(state, groups): void {
        state.accessGroups = groups;
    },

    addAccessGroup(state, group): void {
        state.accessGroups.push(group.name);
    },

    removeAccessGroup(state, groupName): void {
        state.accessGroups = state.accessGroups.filter(group => group.name !== groupName);
    },

    setSelectedAccessGroup(state, group): void {
        state.selectedAccessGroup = group;
    },

    updateSelectedAccessGroup(state, modifiedGroup): void {
        state.accessGroups.forEach(group => {
            if (group.name == state.selectedAccessGroup.name) {
                group.name = modifiedGroup.name;
            }
        })
        state.selectedAccessGroup = modifiedGroup;
    },

    resetSelectedAccessGroup(state): void {
        state.selectedAccessGroup = undefined;
    },

    setScopeTypes(state, types: string[]){
        state.scopeTypes = types;
    },

    setTypeScopes(state, scopes: string[]){
        state.typeScopes = scopes;
    },

    setTemplateUserAccessSettings(state, settings){
        state.templateUserAccessSettings = settings;
    }

}