export default {
    persons(state){
        return state.persons;
    },
    hasPersons(state){
        return state.persons && state.persons.length >0;
    },
    messages(state){
        return state.messages;
    },
    hasMessages(state){
        return state.messages && state.messages.length>0;
    }
}