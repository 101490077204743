export default {
    loadToasts({commit}, toasts) {
        commit("loadToasts", toasts);
    },
    addToast({commit,dispatch}, toast){
        commit("addToast", toast);
        setTimeout(()=> {
            dispatch("removeToast", toast.id);
        },3000);
    },
    removeToast({commit}, toastId) {
        commit("removeToast", toastId);
    }
}