export default {
    setTasks(state, payload) {
        state.tasks = payload;
    },

    addTask(state, payload) {
        state.tasks.push(payload);
    },

    deleteTask(state, payload) {
        state.tasks = state.tasks.filter(task => {
            return task.id !== payload;
        });
    },

    updateTask(state, payload) {
        const task_index = state.tasks.map(task => task.created).indexOf(payload.created);
        state.tasks[task_index] = payload;
    },

    deleteAllFromTasks(state) {
        state.tasks = [];
    },
}