import {getISODate} from "@/util/dateFormatter";
import moment from "moment/moment";
import get from "@/util/get";

export default {
    async loadLastYearAttendanceStatisticsForCharts({commit}, data) {
        const today = new Date();

        const {start, end} = data ?? {
            start: moment(today, "YYYY-MM-DD").subtract(1, 'year').format('YYYY-MM-DD'),
            end: getISODate(today)
        };

        const responseData = await get(`${process.env.VUE_APP_URL}/company/attendance-statistics?firstDay=${start}&lastDay=${end}`);
        commit('setCompanyStatistics', responseData.responseData);
    },

    async loadTodayAttendanceStatistics({commit}) {
        const today = getISODate(new Date());

        const responseData = await get(`${process.env.VUE_APP_URL}/company/attendance-statistics?firstDay=${today}&lastDay=${today}`);
        commit('setTodayAttendanceStatistics', responseData.responseData);
    },

    async loadCompanyEmployeeAmount({commit}){
        const responseData = await get(`${process.env.VUE_APP_URL}/company/employee-amount`);
        commit('setCompanyEmployeesAmount', responseData.responseData);
    },

    async loadCompanyOnlineEmployeeAmount({commit}){
        const responseData = await get(`${process.env.VUE_APP_URL}/company/online-employee-amount`);
        commit('setCompanyOnlineEmployeesAmount', responseData.responseData);
    },

    async loadCompanyFixedAssetsAmount({commit}){
        const responseData = await get(`${process.env.VUE_APP_URL}/inventory/items/total`);
        commit('setCompanyFixedAssetsAmount', responseData.responseData);
    },

    async loadCompanyProjectsAmount({commit}){
        const responseData = await get(`${process.env.VUE_APP_URL}/company/projects/total`);
        commit('setCompanyProjectsAmount', responseData.responseData);
    }
}