export default {
    setProjectWorkingHours(state, payload) {
        state.project_working_hours = payload;
    },
    deleteWH(state, payload) {
        state.project_working_hours.forEach(day => {
            if(day.weekDay === payload.weekDay) {
                day.startTime = null;
                day.endTime = null;
                day.requiredWorkHours = null;
            }
        });
    },
    setSelectedProject(state, payload) {
        state.selectedProject = payload;
    },
    modifySelectedProject(state, payload) {
        state.selectedProject.projectName = payload.projectName;
        state.selectedProject.projectCode = payload.projectCode;
        state.selectedProject.projectDescription = payload.projectDescription;
    }
}