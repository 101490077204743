<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
    name: "VerticalHeaderButton",
    props: ["text", "url", "icon"]
})
</script>

<template>
    <router-link :to="url" class="cursor-pointer" @click="$emit('closeMenu')">
        <div class="pt-10 flex items-center focus:outline-none focus:border-blue-700 focus:border-b-2">
            <div class="w-6 h-6 md:w-8 md:h-8">
                <i :class="icon"></i>
            </div>
            <p tabindex="0" class="text-base ml-3">{{ text }}</p>
        </div>
    </router-link>
</template>

<style scoped>

</style>