import get from "@/util/get";
import post from "@/util/post";
import patch from "@/util/patch";
import del from "@/util/delete";

export default {
    async clearStorage({commit}) {
        commit('deleteAllFromInventory');
    },
    async loadInventory({commit}) {
        const responseData = await get(`${process.env.VUE_APP_URL}/inventory/items/full/list`);
        commit('setInventory', responseData.responseData);
    },
    async loadInventoryAssignedByAssigneeUuid({commit}, assigneeUuid: number) {
        const responseData = await get(`${process.env.VUE_APP_URL}/inventory/items/full/list?assigneeUuid=${assigneeUuid}`);
        commit('setInventory', responseData);
        return responseData;
    },
    async addToInventory({commit}, data) {
        await post(`${process.env.VUE_APP_URL}/inventory/items`, JSON.stringify(data));
        commit('addToInventory', data);
    },
    async editItemInInventory({commit}, item) {
        const  response = await patch(`${process.env.VUE_APP_URL}/inventory/items`,JSON.stringify(item));
        commit('editItem', item);
    },
    async deleteItem({commit}, item) {
        await del(`${process.env.VUE_APP_URL}/inventory/items?name=${item.name}&created=${item.created}`);
        commit('deleteFromInventory', item);
    },
}