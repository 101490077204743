export class Logger {

    private static getTimestamp(): string {
        const date: Date = new Date();

        return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`
    }

    public static info(...message: any): void {
        console.info(Logger.getTimestamp(), " [INFO] ", ...message);
    }

    public static error(...message: any): void {
        console.error(Logger.getTimestamp(), " [ERROR] ", ...message);
    }
}