
export default {
    getNotifications(state) {
        return state.notifications;
    },
    hasNotifications(state){
        return state.notifications && state.notifications.length > 0;
    },
    notificationAmount(state){
        return state.notifications.length;
    }
}