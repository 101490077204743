export default {
    registerEmployee(state, payload) {
        state.employees.push(payload);
    },
    loadEmployees(state, payload) {
        state.employees = [];
        state.employees = payload;
    },
    editSelectedEmployee(state, payload) {
        const employee = state.employees.find(employee => employee.uuid === payload.uuid);
        const result = Object.assign(employee, payload);
        state.employees.push(result);
    },
    loadSelectedEmployee(state, payload) {
        state.selectedEmployee = payload;
    },
    addComment(state, payload) {
        state.selectedEmployeeFeedbacks.push(payload);
    },
    setFeedbacks(state, payload) {
        state.selectedEmployeeFeedbacks = payload;
    },
    addManager(state, payload) {
        state.selectedEmployee.managers.push(payload);
    },
    removeManager(state, payload) {
        state.selectedEmployee.managers = state.selectedEmployee.managers.filter(manager => manager.uuid !== payload.managerUUID);
    },
    loadSearchResults(state, payload) {
        state.searchResults = payload;
    },
    resetSearchResults(state) {
        state.searchResults = null;
    },
    resetFeedbacks(state) {
        state.selectedEmployeeFeedbacks = [];
    },
    resetAddedEmployee(state, payload) {
        state.addedEmployee = null;
    },
    setLoading(state, payload) {
        state.loading = payload
    },
    setError(state, payload) {
        state.error = payload
    }
}