import refreshToken from "@/util/refreshToken";

export const getResponseData = async (response: Response) => {
    let responseData;
    const status = response.status;

    switch (response.headers.get('Content-Type')) {
        case 'application/json': {
            responseData = await response.json();
            break;
        }
        case 'text/plain;charset=UTF-8': {
            responseData = await response.text();
            break;
        }
        default: {
            responseData = null;
        }
    }

    return {responseData, status};
}

export const manageResponseException = async (error, callback) => {
    const err = error as Error;
    if (err.message === "401 Unauthorized" && localStorage.getItem("token")) {
        try {
            await refreshToken();
        } catch (e: any) {
            if (e.message === "401 Unauthorized") {
                localStorage.removeItem("token");
                localStorage.removeItem("user");

                if (location.pathname !== "/") {
                    location.pathname = "/";
                }
            }
            return;
        }
        return callback();
    } else {
        throw err;
    }
}