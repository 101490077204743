<template>
<!--  <transition>-->
<!--    <div v-if="open" :class="[typeToast(this.type), 'w-80']">-->
<!--      <div class="flex p-4 justify-between items-center cursor-pointer" @click="closeToast">-->
<!--        <i class="fa-solid fa-circle-check mt-1" v-if="this.type==='success'"></i>-->
<!--        <i class="fa-solid fa-ban mt-1" v-if="this.type==='danger'"></i>-->
<!--        <i class="fa-solid fa-circle-info mt-1" v-if="this.type==='info'"></i>-->
<!--        <i class="fa-solid fa-triangle-exclamation mt-1" v-if="this.type==='warn'"></i>-->
<!--        <span>{{ title }}</span>-->

<!--        <button @click.stop="closeToast()" type="button" class="inline-flex flex-shrink-0 justify-center items-center h-6 w-6 rounded-md text-white/[.5] hover:text-white focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-white transition-all text-sm dark:focus:ring-offset-gray-800">-->
<!--          <i class="fa-solid fa-xmark"></i>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </transition>-->
  <div :class="[typeToast(this.type), 'toast']">
    <div class="container-1">
      <i :class="iconToast(type)"></i>
    </div>
    <div class="container-2">
      <p>{{title}}</p>
      <p>{{message}}</p>
    </div>
    <button @click="closeToast">&times;</button>
  </div>
</template>

<script>
import {watchEffect} from "vue";

export default {
    name: "SuccessToast",
    props: ["id","title", "message", "show", "type", "fullWidth", "mode"],
    mounted() {
        watchEffect(() => {
            this.open = this.show;
        });

        if(this.mode==="alert"){
          setTimeout(()=> {
            this.$emit('close');
          },5000)
        }
    },
    data() {
        return {
            open: this.show,
            success: "success ",
            error: "error ",
            info: "info ",
            warning: "warning ",
            icon: "",
        }
    },
    computed: {
        typeToast() {
            return (type) => {
                if (type === 'warn') {
                    return this.warning
                }
                if (type === 'danger') {
                    return this.error
                }
                if (type === 'success') {
                    return this.success
                }
                if (type === 'info') {
                    return this.info
                }
            };
        },
        iconToast(){
          return (type) => {
            if (type === 'warn') {
               return "fas fa-exclamation-circle"
            }
            if (type === 'danger') {
              return "fas fa-times-circle"
            }
            if (type === 'success') {
              return "fas fa-check-circle"
            }
            if (type === 'info') {
              return "fas fa-info-circle"
            }
          }
      }
    },
    methods: {
        closeToast() {
          if(this.mode==="alert"){
            this.$emit('close');
          } else {
            this.$store.dispatch("toasts/removeToast", this.id);
          }
        }
    }
}
</script>

<style scoped>
.toast{
  width: 100%;
  height: 80px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 7px;
  display: grid;
  grid-template-columns: 1.3fr 6fr 0.5fr;
  box-shadow: 0 15px 30px rgba(0,0,0,0.08);
}
.success{
  border-left: 8px solid #47D764;
}
.error{
  border-left: 8px solid #ff355b;
}
.info{
  border-left: 8px solid #2F86EB;
}
.warning{
  border-left: 8px solid #FFC021;
}
.error i{
  color: #ff355b;
}
.info i{
  color: #2F86EB;
}
.warning i{
  color: #FFC021;
}
.toast:not(:last-child){
  margin-bottom: 10px;
}
.container-1,.container-2{
  align-self: center;
}
.container-1 i{
  font-size: 35px;
}
.success i{
  color: #47D764;
}
.container-2 p:first-child{
  color: #101020;
  font-weight: 600;
  font-size: 16px;
}
.container-2 p:last-child{
  font-size: 12px;
  font-weight: 400;
  color: #656565;
}
.toast button{
  align-self: flex-start;
  background-color: transparent;
  font-size: 25px;
  color: #656565;
  line-height: 0;
  cursor: pointer;
}
</style>