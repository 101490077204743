import mutations from "@/store/modules/userdataform/mutations";
import getters from "@/store/modules/userdataform/getters";
import actions from "@/store/modules/userdataform/actions";

export default {
    namespaced: true,
    state(){
        return {
            contactTypes: []
        }
    },
    mutations,
    getters,
    actions
}