import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
    namespaced: true,
    state(){
        return {
            timesheets: [],
            projects: [],
            tasks: [],
            total_required_hours: null,
            subordinates: null,
            available_periods: [],
            available_timesheet: null,
            selectedEmployee: {
                "uuid": "",
                "name": "",
                "surname": "",
                "country": "",
                "city": "",
                "specialization": "",
                "office": null,
                "online": false
            }
        }
    },
    mutations,
    getters,
    actions
}