import { createStore } from 'vuex';
import employeesModule from './modules/employees/index';
import userModule from './modules/user/index';
import countriesModule from './modules/countries/index';
import companiesModule from './modules/companies/index';
import labelsModule from './modules/labels/index';
import settingsModule from './modules/settings/index';
import chatModule from './modules/chat/index';
import calendarModule from './modules/calendar';
import timesheetModule from './modules/timesheets';
import companyModule from "@/store/modules/company";
import projectsModule from "@/store/modules/projects";
import projectTasksModule from "@/store/modules/projecttasks";
import employeeTasksModule from "@/store/modules/employeetasks";
import inventoryModule from "@/store/modules/inventory";
import dashboardModule from "@/store/modules/dashboard";
import approvalsModule from "@/store/modules/approvals";
import helpModule from "@/store/modules/help";
import utilModule from "@/store/modules/util";
import userDataFormModule from "@/store/modules/userdataform";
import companyConfiguration from "@/store/modules/companyconfiguration";
import officeModule from "@/store/modules/office";
import toastsModules from "@/store/modules/toasts";
import notificationsModule from "@/store/modules/notifications";

export default createStore({
  modules: {
    employees: employeesModule,
    user: userModule,
    countries: countriesModule,
    companies: companiesModule,
    companyConfiguration: companyConfiguration,
    labels: labelsModule,
    settings: settingsModule,
    chat: chatModule,
    calendar: calendarModule,
    timesheets: timesheetModule,
    company: companyModule,
    projects: projectsModule,
    projectTasks: projectTasksModule,
    employeeTasks: employeeTasksModule,
    inventory: inventoryModule,
    dashboard: dashboardModule,
    approvals: approvalsModule,
    help: helpModule,
    util: utilModule,
    userDataForm: userDataFormModule,
    office: officeModule,
    toasts: toastsModules,
    notifications: notificationsModule
  },
  state(){
    return {

    }
  },
  getters: {

  },
  mutations: {
  },
  actions: {
  },
})
