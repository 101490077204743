export class Theme {
    private static systemThemeAuto = false;

    static {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
            if(this.systemThemeAuto){
                const newColorScheme = event.matches ? "dark" : "light";
                this.setColor(newColorScheme);
            }
        });
    }

    public static modify(newVal: string) {
        this.systemThemeAuto = newVal === "auto";
        this.setColor(newVal);
    }

    private static setColor(color: string) {
        const html = document.querySelector("html");
        html.classList.remove("dark");
        switch (color) {
            case "dark": {
                html.classList.add("dark");
                break;
            }
            case "auto": {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    html.classList.add("dark");
                }
                break;
            }
        }
    }
}