export default {
    setDialogsList(state, payload) {
        state.persons = payload;
    },
    updateLastDialogMessage(state, payload) {
        for (let i = 0; i < state.persons.length; i++) {
            if (state.persons[i].message.dialogId === payload.dialogId) {
                const isTheLastElement = i === state.persons.length - 1;
                state.persons = [
                    {
                        isUserOnline: state.persons[i].isUserOnline,
                        message: payload
                    }
                ].concat(state.persons.slice(0, i), isTheLastElement ? [] : state.persons.slice(i + 1));
            }
        }
    },
    setMessageHistory(state, payload) {
        state.messages = payload;
    },
    addMessage(state, payload) {
        state.messages.push(payload);
    },
    resetMessages(state, payload) {
        state.messages = null;
    }
}