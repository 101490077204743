import get from "@/util/get";
import post from "@/util/post";
import put from "@/util/put";
import del from "@/util/delete";

export default {
    async loadAccessGroupsList({commit}): Promise<void> {
        const accessGroupsListResponse = await get(`${process.env.VUE_APP_URL}/settings/access-groups`);
        commit("setAccessGroups", accessGroupsListResponse.responseData);
    },

    async loadAccessGroup({commit}, groupName: string): Promise<void> {
        const accessGroupResponse = await get(`${process.env.VUE_APP_URL}/settings/access-groups/${groupName}`);
        const accessGroup = accessGroupResponse.responseData;
        const accessGroupUserNamesResponse = await get(`${process.env.VUE_APP_URL}/users-list/names?userUuidList=${accessGroup.users.join(",")}`);
        accessGroup.users = accessGroupUserNamesResponse.responseData;
        commit("setSelectedAccessGroup", accessGroup);
    },

    async loadScopeTypes({commit}){
        const response = await get(`${process.env.VUE_APP_URL}/settings/scopes/types`);
        commit("setScopeTypes", response.responseData)
    },

    async loadTypeScopes({commit}, type: string){
        const response = await get(`${process.env.VUE_APP_URL}/settings/scopes/${type}`);
        commit("setTypeScopes", response.responseData)
    },

    async createAccessGroup({commit}, group): Promise<void> {
        await post(`${process.env.VUE_APP_URL}/settings/access-groups`, JSON.stringify(group));
        commit("addAccessGroup", group);
    },

    async updateAccessGroup({commit}, {oldGroupName, group}): Promise<void> {
        await put(`${process.env.VUE_APP_URL}/settings/access-groups/${oldGroupName}`, JSON.stringify(group));
        commit("updateSelectedAccessGroup", group);
    },

    async resetSelectedAccessGroup({commit}): Promise<void> {
        commit("resetSelectedAccessGroup");
    },

    async deleteAccessGroup({commit}, groupName: string): Promise<void> {
        await del(`${process.env.VUE_APP_URL}/settings/access-groups/${groupName}`);
        commit("removeAccessGroup", groupName)
    },

    async loadTemplateUserAccessSettings({commit}){
        const userAccessSettingsResponse = await get(`${process.env.VUE_APP_URL}/settings/user-access-settings/grouped`);
        commit("setTemplateUserAccessSettings", userAccessSettingsResponse.responseData)
    }
}