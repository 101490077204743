import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
    state(){
        return {
            user: JSON.parse(localStorage.getItem('user')) || null,
            token: localStorage.getItem('token') || null,
            status: '',
            registeredUuid: undefined,
            authenticatedUser: JSON.parse(localStorage.getItem('auth_user') || null),
            authenticatedUserAvatar: require('../../../assets/user-placeholder.jpg'),
            detailInfo: null,
            userStats: null,
            dataForPrefill: null,
        }
    },
    mutations,
    getters,
    actions
}