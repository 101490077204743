import {loadImage} from "@/util/image-utils";
import get from "@/util/get";
import post from "@/util/post";
import patch from "@/util/patch";
import del from "@/util/delete";
import put from "@/util/put";

export default {
    async loadCompanyLogo(context, data) {
        return loadImage(`/picture?imageType=COMPANY_LOGO&iid=${data}`);
    },

    async loadProjects({commit}) {
        const response = await get(`${process.env.VUE_APP_URL}/company/projects/full`)
        commit('setProjects', response.responseData);
    },

    async addProject({commit}, data) {
        const responseData = await post(`${process.env.VUE_APP_URL}/company/projects`, JSON.stringify(data));
        data.puid = responseData;
        commit('addProject', data);
        commit('setAddedProject', responseData);
    },

    async deleteProject({commit}, data){
        await del(`${process.env.VUE_APP_URL}/company/projects?name=${data.projectName}`);
        commit('deleteProject', data);
    },

    async setProjectLogo(context, data: FormData) {
        return await put(`${process.env.VUE_APP_URL}/image`, data);
    },

    async addUpdate({commit}, data) {
        const responseData = await post(`${process.env.VUE_APP_URL}/company/updates`, JSON.stringify(data));
        commit('addUpdate', data);
        commit('setAddedUpdate', responseData);
    },

    async getUpdates({commit}) {
        const response = await get(`${process.env.VUE_APP_URL}/company/updates`);
        const {responseData, status}  = response;
        commit('setUpdates', responseData);
    },

    async getUpdate({commit}, data) {
        const response  = await get(`${process.env.VUE_APP_URL}/company/updates?id=${data}`);
        const {responseData, status}  = response;
        commit('setSelectedUpdate', responseData);
    },
    async deleteUpdate({commit}, data){
        const response  = await del(`${process.env.VUE_APP_URL}/company/updates?date=${data}`);
        commit('deleteUpdate', data);
    },
    async editUpdate({commit}, data){
        const editUpdate = await patch(`${process.env.VUE_APP_URL}/company/updates`, JSON.stringify(data));
        commit('editUpdate', data);
    },

    async getCompanyBusinessHours({commit}) {
        const response = await get(`${process.env.VUE_APP_URL}/company/business-hours`)
        commit('setWorkingHours', response.responseData);
    },

    async addOffice({commit}, data) {
        await post(`${process.env.VUE_APP_URL}/company/offices`, JSON.stringify(data));
        commit('addOffice', data);
    },

    async loadOffices({commit}) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/offices`);
        commit('setOffices', responseData.responseData);
    },
    async deleteOffice({commit}, data){
        await del(`${process.env.VUE_APP_URL}/company/offices?name=${data}`);
        commit('deleteOffice', data);
    },
    setDefaultWorkingHours({commit}, data) {
        commit('setDefaultWH', data);
    },

    async saveWH(context, data) {
        await post(`${process.env.VUE_APP_URL}/company/business-hours`, JSON.stringify(data));
    },

    async updateWH(context, data) {
        await patch(`${process.env.VUE_APP_URL}/company/business-hours`, JSON.stringify(data));
    },

    async deleteWH({commit}, data) {
        await del(`${process.env.VUE_APP_URL}/company/business-hours?projectName=${data.projectName}&weekDay=${data.cuid}`);

        commit('deleteWH', data);
    },
    async assignEmployeeOnProject({commit}, data) {
        await post(`${process.env.VUE_APP_URL}/company/employee/projects/assign`, JSON.stringify(data));
        commit("addProjectEmployeeByUUID", data.userId)
    },

    async loadAttendanceStatistics({commit}, data) {
        const {projectName, startDate} = data;
        const firstDay = startDate.split("T")[0] + "T00:00";
        const lastDay = startDate.split("T")[0] + "T23:59";
        const responseData = await get(`${process.env.VUE_APP_URL}/company/projects/properties/attendance-statistics?projectName=${projectName}&firstDay=${firstDay}&lastDay=${lastDay}`);
        commit('setAttendanceStatistics', responseData.responseData);
    },

    async loadProjectEmployees({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/projects/employees?projectName=${data}`);
        commit('setProject_employees', responseData.responseData);
    },

    async fireEmployeeFromProject({commit}, data) {
        await patch(`${process.env.VUE_APP_URL}/company/employee/projects/fire`, JSON.stringify(data));
        commit('fireEmployeeFromProject', data);
    },

    async loadNonProjectEmployees({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/all-non-project-employees?projectName=${data}`);
        commit('setNonProjectEmployees', responseData);
    },

    //PROJECT

    setDefaultProjectWorkingHours({commit}, data) {
        commit('setDefaultProjectWH', data);
    },

    async setProjectWorkingHours({commit}, data) {
        const id = +data;

        const responseData = await get(`${process.env.VUE_APP_URL}/company/projects/properties/working-hours?puid=${id}`);
        commit('setProjectWorkingHours', responseData);
    },

    async setCompanyStatistics({commit}, data) {
        const {start, end} = data;

        const responseData = await get(`${process.env.VUE_APP_URL}/company/attendance-statistics/with-empls?firstDay=${start}&lastDay=${end}`);
        commit('setCompanyStats', responseData);
    },

    resetProjects({commit}) {
        commit("resetProjects");
    },

    resetAddedUpdate({commit}) {
        commit('resetAddedUpdate');
    }
}