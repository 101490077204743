import get from "@/util/get";

export default {
    async approveEmployee({commit}, data) {
        try {
            commit('setLoading', true);
            await get(`${process.env.VUE_APP_URL}/email/approve-by-employee?token=${data}`);
            commit('setSuccessfulApproved', true);
        } catch (error) {
            commit('setSuccessfulApproved', false);
            commit('setError', error);
            throw error;
        } finally {
            commit('setLoading', false);
        }
    },
}