import {WebSocketClient} from "@/util/WebSocketClient";
import get from "@/util/get";

export default {
    async loadDialogsList({commit}) {
        const responseData = await get(`${process.env.VUE_APP_URL}/dialogs/list`);
        commit('setDialogsList', responseData.responseData);
    },
    async loadMessagesHistory({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/messages/history?dialogId=${data}`);
        commit('setMessageHistory', responseData.responseData);
    },
    async addMessage(context, data) {
        context.commit("addMessage", data);
        context.commit("updateLastDialogMessage", data);
    },
    resetMessages(context) {
        context.commit("resetMessages");
    },
    sendMessage(context, message) {
        WebSocketClient.getWebSocket().sendMessage(message);
    }
}