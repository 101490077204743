import {loadImage} from "@/util/image-utils";

export default {
    user(state){
        return state.user
    },
    userFirstName(state){
        return state.user ? state.user.name : "-";
    },
    userLastName(state){
        return state.user ? state.user.surname : "-";
    },
    userEmail(state){
        return state.user ? state.user.email : '-'
    },
    userId(state){
        return state.user ? state.user.uuid : null;
    },
    userCompany(state){
        return state.user.company;
    },
    userCompanyId(state){
        return state.user.company.cuid;
    },
    userName(state){
        return state.user
    },
    authenticatedUserAvatar(state){
        return state.authenticatedUserAvatar;
    },
    hasUser(state){
        return !!state.user;
    },
    token(state){
        return state.token
    },
    isAuthenticated(state){
        return !!state.token
    },
    getStatus(state){
        return state.status
    },
    getLoadingStatus(state) {
        return state.loading;
    },
    getDetailInfo(state){
        return state.detailInfo;
    },
    dataForPrefill(state){
        return state.dataForPrefill;
    },
    getUserOffice(state){
        return state.user.office ?? 0;
    },
    getUserStats(state){
        return state.userStats
    },
    getTotalPaidDaysOff(state){
        return state.userStats && "totalPaidDaysOff" in state.userStats ? state.userStats["totalPaidDaysOff"] : 0
    },
    getRemainingPaidDaysOff(state){
        return state.userStats && "remainingPaidDaysOff" in state.userStats ? state.userStats["remainingPaidDaysOff"] : 0
    },

    getTakenPaidDaysOff(state){
        return state.userStats && "takenPaidDaysOff" in state.userStats ? state.userStats["takenPaidDaysOff"] : 0
    },

    getTotalPaidSickDays(state){
        return state.userStats && "totalPaidSickDays" in state.userStats ? state.userStats["totalPaidSickDays"] : 0
    },
    getTakenPaidSickDays(state){
        return state.userStats && "takenPaidSickDays" in state.userStats ? state.userStats["takenPaidSickDays"] : 0
    },

    getRemainingPaidSickDays(state){
        return state.userStats && "remainingPaidSickDays" in state.userStats ? state.userStats["remainingPaidSickDays"] : 0
    },

    getTotalUnpaidDaysOff(state){
        return state.userStats && "totalUnpaidDaysOff" in state.userStats ? state.userStats["totalUnpaidDaysOff"] : 0
    },
    getTakenUnpaidDaysOff(state){
        return state.userStats && "takenUnpaidDaysOff" in state.userStats ? state.userStats["takenUnpaidDaysOff"] : 0
    },
    getRemainingUnpaidDaysOff(state){
        return state.userStats && "remainingUnpaidDaysOff" in state.userStats ? state.userStats["remainingUnpaidDaysOff"] : 0
    },

    getTotalUnpaidSickDays(state){
        return state.userStats && "totalUnpaidSickDays" in state.userStats ? state.userStats["totalUnpaidSickDays"] : 0
    },
    getTakenUnpaidSickDays(state){
        return state.userStats && "takenUnpaidSickDays" in state.userStats ? state.userStats["takenUnpaidSickDays"] : 0
    },
    getRemainingUnpaidSickDays(state){
        return state.userStats && "remainingUnpaidSickDays" in state.userStats ? state.userStats["remainingUnpaidSickDays"] : 0
    },
    getError(state){
        return state.error
    },
    successfulApproved(state){
        return state.success
    },
}

