export default {
    events(state){
        return state.events;
    },
    birthdays(state){
        return state.birthdays;
    },
    weekendsVisible(state){
        return state.weekendsVisible;
    }
}