<template>
  <TheHeader v-if="showHeader"/>
  <div class="h-screen" style="padding-top: 60px;">
    <ToastsList/>
    <router-view/>
  </div>
  <!--  dark:bg-neutral-900 dark:text-neutral-300-->
</template>

<style>
body {
  font-family: 'Ubuntu', sans-serif;
}

</style>

<script lang="ts">
import {defineComponent} from "vue";
import TheHeader from "@/components/ui/header/TheHeader.vue";
import {Theme} from "@/util/theme";
import {WebSocketClient} from "@/util/WebSocketClient";
import {useI18n} from "vue-i18n";
import ToastsList from "@/components/core/Toasts/ToastsList.vue";

export default defineComponent({
  async mounted() {
    if (this.isAuthRequired()) {
      await Promise.all([
        this.$store.dispatch(`loadAuthenticatedUser`, undefined, this.$store, this.$router),
        this.$store.dispatch(`settings/loadSettings`, undefined, this.$store, this.$router)
      ]);
      const language = this.$store.getters["settings/getLanguage"];
      // localStorage.getItem("language") ?localStorage.setItem("language", JSON.stringify(language.value)) : "en";
      WebSocketClient.getWebSocket();
      this.$store.dispatch("toasts/loadToasts", []);
    }

    Theme.modify(this.theme)
  },
  components: {
    TheHeader,
    ToastsList
  },
  computed: {
    showHeader() {
      return this.$store.getters["util/showMenu"];
    },
    theme() {
      return this.$store.getters["settings/getTheme"];
    },
    language(){
      const language = this.$store.getters["settings/getLanguage"];
      // localStorage.setItem("language", JSON.stringify(language.value));
      localStorage.setItem("language", JSON.stringify(language?.value ?? "en"));
      return language;
    }
  },
  methods: {
    isAuthRequired(){
      const parsedLocation = location.pathname.split("/");
      return this.$router.getRoutes().find(
        route => {
          if (route?.meta?.requiresAuth) {
            const parsedPathTemplate = route.path.split("/");
            if (parsedLocation.length === parsedPathTemplate.length) {
              for (const i in parsedLocation) {
                if (parsedPathTemplate[i].startsWith(":")) {
                  continue;
                }

                if (parsedLocation[i] !== parsedPathTemplate[i]) {
                  return false;
                }
              }
              return true;
            }
          }
          return false;
        }
      );
    }
  }
});
</script>
