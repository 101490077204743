import * as Mutation from './mutation-types'
import get from "@/util/get";
import post from "@/util/post";
import patch from "@/util/patch";
import del from "@/util/delete";
import {getISODate, getISODateTime, getTodayISODate} from "@/util/dateFormatter";

export default {
    async createEvent({commit}, event) {
        const responseData = await post(`${process.env.VUE_APP_URL}/events`,JSON.stringify(event));
        event.id = +responseData;
        commit(Mutation.CREATE_EVENT, event);
        return event;
    },

    async getEvents({commit}) {
        const responseData = await get(`${process.env.VUE_APP_URL}/events`);
        commit(Mutation.GET_EVENT, responseData.responseData)
        return responseData;
    },

    async getStructuredEvents({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/events/structured/month?startTime=${data.start}&endTime=${data.end}`);
        commit(Mutation.GET_EVENT, responseData.responseData)
        return responseData.responseData;
    },

    async loadTodayEvents({commit}) {
        const today = getTodayISODate();
        const startTime = getISODateTime(new Date(`${today} 00:00`));
        const endTime = getISODateTime(new Date(`${today} 23:59`));

        const events = await get(`${process.env.VUE_APP_URL}/events?startTime=${startTime}&endTime=${endTime}`);
        commit(Mutation.GET_EVENT, events.responseData);
    },

    async loadTodayBirthdays({commit}) {
        const today = getTodayISODate();
        const firstDay = getISODate(new Date(`${today} 00:00`));
        const lastDay = getISODate(new Date(`${today} 23:59`));

        const birthdays = await get(`${process.env.VUE_APP_URL}/events/birthdays?startDate=${firstDay}&endDate=${lastDay}`);
        const proxy_birthdays = birthdays.responseData.map(birthday => {
            return {...birthday.responseData, "id": 0}
        });
        commit('setBirthdays', proxy_birthdays);
    },


    async getBirthdays({commit}) {
        const responseData = await get(`${process.env.VUE_APP_URL}/events/birthdays`);
        commit(Mutation.GET_EVENT, responseData)
        return responseData;
    },

    async editEvent( {commit}, event) {
        await patch(`${process.env.VUE_APP_URL}/events`, JSON.stringify(event));
        commit(Mutation.UPDATE_EVENT, event);
    },

    updateEvent({commit}, updatedEvent) {
        return commit(Mutation.UPDATE_EVENT, updatedEvent)
    },
    async deleteEvent({commit}, event) {
        await del(`${process.env.VUE_APP_URL}/events?eventId=${event.id}`);
        commit(Mutation.DELETE_EVENT, event);
    },
    setweekendsVisible({commit}, enabled) {
        return commit(Mutation.SET_WEEKENDS_ENABLED, enabled)
    }
}
