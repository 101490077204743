import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4fc06ff0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toast-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts, (toast) => {
      return (_openBlock(), _createBlock(_component_Toast, {
        key: toast.id,
        id: toast.id,
        title: toast.title,
        message: toast.message,
        type: toast.type,
        show: toast.show
      }, null, 8, ["id", "title", "message", "type", "show"]))
    }), 128))
  ]))
}