import get from "@/util/get";
import patch from "@/util/patch";
import {Theme} from "@/util/theme";
import put from "@/util/put";

export default {
    async changePassword(context, data) {
        return await patch(`${process.env.VUE_APP_URL}/aaa/change-password`, JSON.stringify(data));
    },

    async loadSettings({commit}) {
        try {
            const response = await get(`${process.env.VUE_APP_URL}/settings/user`);
            const responseData = response.responseData;
            const settings = {};
            responseData.forEach(settingObj => {
                const {value, scopes} = settingObj;
                settings[settingObj.name] = {value, scopes}
            })
            commit('setSettings', settings);
            if(responseData["USER.PREF_THEME"]) {
                commit('setTheme', responseData["USER.PREF_THEME"]);
                Theme.modify(responseData["USER.PREF_THEME"]);
                localStorage.setItem("theme", responseData["USER.PREF_THEME"]);
            }
        }
        catch (error){
            return;
        }
    },

    async updateSettings({commit}, data)  {
        await patch(`${process.env.VUE_APP_URL}/settings`, JSON.stringify(data));
        commit("updateSettings", data);
    },

    async changeLanguage({commit}, data){
        await put(`${process.env.VUE_APP_URL}/settings/user`, JSON.stringify(data));
        commit("setLanguage",  data);
    }
}