import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import store from '../store/index';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Login',
        // redirect: '/homepage',
        component: () => import(/* webpackChunkName: "about" */ '../views/LoginPage.vue'),
        // component: () => import(/* webpackChunkName: "about" */ '@/components/ui/homepage/HomePage.vue'),
        meta: {requiresAuth: false}
    },
    {
        path: '/email-confirmed',
        name: 'AccountConfirmationByEmployee',
        // redirect: '/homepage',
        component: () => import(/* webpackChunkName: "about" */ '@/components/ui/approvals/AccountConfirmationByEmployee.vue'),
        meta: {requiresAuth: false}
    },
    {
        path: '/employee-approval',
        name: 'AccountApprovalByEmployee',
        component: () => import('@/components/ui/approvals/AccountApprovalByEmployee.vue'),
        meta: {requiresAuth: true},
    },
    {
        path: '/email-confirmation-by-company',
        name: 'AccountApprovalByCompany',
        component: () => import('@/components/ui/approvals/AccountApprovalByCompany.vue'),
        meta: {requiresAuth: false, privateVisible: true},
    },
    {
        path: '/company/email/confirm',
        name: 'CompanyConfirmation',
        component: () => import('@/components/ui/approvals/CompanyConfirmation.vue'),
        meta: {requiresAuth: false, privateVisible: true},
    },
    {
        path: '/calendar',
        name: 'Calendar',
        // redirect: '/homepage',
        component: () => import('@/components/ui/calendar/TheCalendar.vue'),
        // component: () => import('../components/NewCalendar.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/timesheet',
        name: 'TimeSheets',
        component: () => import('@/components/ui/timesheets/TimeSheets.vue'),
        meta: {requiresAuth: true},
        redirect: '/timesheet',
        children: [
            {
                path: 'my',
                name: 'MyTimesheets',
                component: () => import('@/components/ui/timesheets/MyTimesheets.vue'),
                meta: {requiresAuth: true},
            },
            {
                path: 'company',
                name: 'CompanyTimeSheet',
                component: () => import('@/components/ui/timesheets/CompanyTimeSheet.vue'),
                meta: {requiresAuth: true}
            }
        ]
    },

    {
        path: '/help',
        name: 'Help',
        component: () => import(/* webpackChunkName: "about" */ '../views/HelpPage.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "about" */ '../components/ui/TheRegister.vue')
    },
    {
        path: '/new-chat',
        name: 'new-chat',
        component: () => import(/* webpackChunkName: "about" */ '@/components/ui/chat/TheChat.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/register-company',
        name: 'CompanyRegistration',
        //register-company
        component: () => import(/* webpackChunkName: "about" */ '../components/ui/RegisterCompany.vue')
    },
    {
        path: '/home',
        name: 'Homepage',
        component: () => import(/* webpackChunkName: "about" */ '@/components/ui/homepage/HomePage.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "about" */ '@/components/ui/dashboard/TheDashboard.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/search',
        name: 'search',
        component: () => import(/* webpackChunkName: "about" */ '../components/ui/TheSearch.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/chat',
        name: 'chat',
        component: () => import(/* webpackChunkName: "about" */ '@/components/ui/chat/TheChat.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/employee/:id',
        name: 'EmployeeDetails',
        component: () => import(/* webpackChunkName: "about" */ '../components/ui/employeedetails/EmployeeDetails.vue'),
        props: true,
        meta: {requiresAuth: true}
    },
    {
        path: '/employee/edit/:id',
        name: 'EditEmployee',
        component: () => import('../components/ui/./EditCompanyEmployee.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/add_comment/:id',
        name: 'AddComment',
        component: () => import(/* webpackChunkName: "about" */ '../components/ui/AddComment.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/restricted',
        name: 'Restricted',
        component: () => import(/* webpackChunkName: "restricted" */ '../components/ui/NotSupported.vue')
    },
    /*SETTINGS*/
    {
        path: '/settings-manager',
        name: 'SettingsPage',
        component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/settings/SettingsMain.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/settings-manager/profile',
        name: 'ModifyUserInfo',
        component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/settings/ModifyLoggedInUserInfo.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/settings-manager/password',
        name: 'CangeUserPassword',
        component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/settings/SettingsPassword.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/settings-manager/language',
        name: 'ChangeUserLanguage',
        component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/settings/SettingsLanguage.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/settings-manager/appearance',
        name: 'ChangeUserAppearance',
        component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/settings/SettingsAppearance.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/settings-manager/company/company-configuration',
        name: 'CompanyConfiguration',
        component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/settings/configuration/CompanyConfiguration.vue'),
        redirect: '/settings-manager/company/company-configuration',
        children: [
            {
                path: 'preferences',
                name: 'CompanyPreferencesConfiguration',
                component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/settings/configuration/preferences/CompanyPreferences.vue'),
                meta: {requiresAuth: true}
            },
            {
                path: 'access',
                name: 'UserAccessConfiguration',
                component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/settings/configuration/access/UserAccessConfiguration.vue'),
                meta: {requiresAuth: true}
            }
        ],
        meta: {requiresAuth: true}
    },

    /*COMPANY*/
    {
        path: '/edit-update/:id',
        name: 'EditUpdate',
        component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/homepage/EditUpdateItem.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/news/:id',
        name: 'NewsPost',
        component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/homepage/NewsPost.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/company',
        name: 'CompanyMain',
        // component: ()=> import(/* webpackChunkName: "restricted" */ '../components/Company/CompanyMain.vue'),
        component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/company/CompanyMain.vue'),
        children: [
            {
                path: '',
                name: 'CompanyGeneral',
                component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/company/CompanyGeneral.vue'),
                meta: {requiresAuth: true}
            },
            {
                path: 'add-update',
                name: 'AddUpdate',
                component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/homepage/AddNewsModal.vue'),
                meta: {requiresAuth: true}
            },
            {
                path: 'add-project',
                name: 'AddProject',
                component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/company/AddProjectModal.vue'),
                meta: {requiresAuth: true}
            },
            {
                path: 'add-employee',
                name: 'EmployeeRegistrationForm',
                component: () => import(/* webpackChunkName: "restricted" */ '../components/ui/company/./AddEmployeeModal.vue'),
                meta: {requiresAuth: true}
            },
            {
                path: 'add-office',
                name: 'AddOffice',
                component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/company/AddOfficeModal.vue'),
                meta: {requiresAuth: true}
            },
            {
                path: 'modify-business-hours',
                name: 'ChangeBusinessHours',
                component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/company/ChangeBusinessHours.vue'),
                meta: {requiresAuth: true}
            },
        ],
        meta: {requiresAuth: true}
    },
    {
        path: '/company/project/:id',
        name: 'CompanyProject',
        props: true,
        component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/company/project/CompanyProject.vue'),
        meta: {requiresAuth: true},
        children: [
            {
                path: '',
                name: 'ProjectEmployees',
                component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/company/project/ProjectEmployees.vue'),
                meta: {requiresAuth: true}
            },
            //     {
            //         path: 'assign-employee',
            //         name: 'AssignEmployee',
            //         component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/company/project/AssignEmployeeModal.vue'),
            //         meta: {requiresAuth: true},
            //         props: true
            //     },
            //     {
            //         path: 'modify-project-business-hours',
            //         name: 'ChangeProjectBusinessHours',
            //         component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/company/project/ChangeProjectBusinessHoursModal.vue'),
            //         meta: {requiresAuth: true},
            //         props: true
            //     },
            //     {
            //         path: 'task-assignment',
            //         name: 'ProjectTask',
            //         component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/company/project/ProjectTasks.vue'),
            //         meta: {requiresAuth: true},
            //         props: true
            //     },
            //     {
            //         path: 'edit',
            //         name: 'EditProject',
            //         component: () => import(/* webpackChunkName: "restricted" */ '@/components/ui/company/project/ModifyProjectModal.vue'),
            //         meta: {requiresAuth: true},
            //         props: true
            //     }
        ]
    },
    {
        path: '/company/office/:id',
        name: 'CompanyOffice',
        props: true,
        component: () => import(/* webpackChunkName: "restricted" */ '../components/ui/company/office/CompanyOffice.vue'),
        meta: {requiresAuth: true},
/*        children: [
            {
                path: '',
                name: 'ProjectEmployees',
                component: () => import(/!* webpackChunkName: "restricted" *!/ '@/components/ui/company/project/ProjectEmployees.vue'),
                meta: {requiresAuth: true}
            },
        ]*/
    },
    {
        path: '/inventory',
        name: 'Inventory',
        component: () => import(/* webpackChunkName: "restricted" */ '../components/ui/fixedassets/CompanyInventory.vue'),
        meta: {requiresAuth: true}
    },
    {
        path: '/tasks',
        name: 'Tasks',
        component: () => import(/* webpackChunkName: "restricted" */ '../components/ui/employeetasks/EmployeeTasks.vue'),
        meta: {requiresAuth: true}
    },

    {
        path: '/password-recovery-code-request',
        name: 'PasswordRecoveryCodeRequest',
        component: () => import('@/components/ui/password-recovery/./PasswordRecoveryCodeRequest.vue')
    },

    {
        path: '/password-recovery',
        name: 'PasswordRecovery',
        component: () => import('@/components/ui/password-recovery/./PasswordRecovery.vue')
    },

    {
        path: '/:notFound(.*)',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "about" */ '../components/ui/NotFound.vue'),
        meta: {privateVisible: true}
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    // linkActiveClass: 'bg-blue-700 text-white'
    linkExactActiveClass: 'border-blue-700 dark:text-white'
});


router.beforeEach(function (to, from, next) {
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        sessionStorage.setItem("afterLoginRedirect", to.fullPath === '/' ? '/home' : to.fullPath);
        next('/');
    } else if (to.meta.requiresAuth && store.getters.isAuthenticated) {
        const path = sessionStorage.getItem("afterLoginRedirect");
        if (path) {
            next(path);
        } else {
            next();
        }
        sessionStorage.removeItem("afterLoginRedirect");
    }
    else if (!to.meta.requiresAuth && store.getters.isAuthenticated) {
        if(to.meta.privateVisible ){
            next();
        } else {
            next('/home');
        }
    }
    else {
        next();
    }
});

export default router;
