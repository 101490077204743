import {getISODateTime, parseDate} from "@/util/dateFormatter";

export default {
    hasError(state){
        return state.error
    },
    employees(state){
        return state.employees;
    },
    hasEmployees(state){
        return state.employees && state.employees.length >0;
    },
    getLengthEmployee(state){
        return state.employees.length;
    },
    getSelectedEmployee(state){
        return state.selectedEmployee;
    },
    getSelectedEmployeeOnline(state){
        return state.selectedEmployee ? state.selectedEmployee.online : '-';
    },
    selectedEmployeeProjects(state){
        return state.selectedEmployee? state.selectedEmployee.projectsHistory: '-';
    },
    selectedEmployeeEducation(state){
        return state.selectedEmployee ? state.selectedEmployee.education : '-';
    },
    fullName(state, getters){
        return state.selectedEmployee ? state.selectedEmployee.name + " "+ state.selectedEmployee.surname : '-';
    },
    employeeName(state, getters){
        return state.selectedEmployee ? state.selectedEmployee.name: '-';
    },
    employeeSurname(state, getters){
        return state.selectedEmployee ? state.selectedEmployee.surname : '-';
    },
    employeeAvatar(state, getters){
        return state.selectedEmployee.avatar;
    },
    employeeDOB(state){
        // return state.selectedEmployee ? state.selectedEmployee.dob : '-';
        return  state.selectedEmployee ?  parseDate(new Date( state.selectedEmployee.dateOfBirth)) : '-';
    },
    phoneNumber(state){
        return state.selectedEmployee ? state.selectedEmployee.phoneNumber : '-';
    },
    email(state){
        return state.selectedEmployee ? state.selectedEmployee.email : '-';
    },
    gender(state){
        const sex = state.selectedEmployee ? state.selectedEmployee.sex : null;
        if(sex === "M"){
            return "Мужской";
        }
        else if(sex === `F`) {
            return "Женский";
        }
        else {
            return '-'
        }
        // return state.selectedEmployee ? state.selectedEmployee.sex : '-';
    },
    fullLocation(state){
        return state.selectedEmployee ? state.selectedEmployee.country + ", "+ state.selectedEmployee.city : '-';
    },
    company(state){
        return state.selectedEmployee ? state.selectedEmployee.company : '-';
    },
    specialization(state){
        return state.selectedEmployee ? state.selectedEmployee.specialization : '-';
    },
    office(state){
        if(state.selectedEmployee) {
            return (state.selectedEmployee.office && state.selectedEmployee.office.id > 0) ? state.selectedEmployee.office.name : 'Not set'
        }
    },
    education(state){
        return state.selectedEmployee ? state.selectedEmployee.education : '-';
    },
    linkedin(state){
        return state.selectedEmployee ? state.selectedEmployee.linkedIn : '-';
    },
    birthCountry(state){
        return state.selectedEmployee ? state.selectedEmployee.birthCountry : '-';
    },
    birthCity(state){
        return state.selectedEmployee ? state.selectedEmployee.birthCity : '-';
    },
    hasFeedbacks(state){
        return state.selectedEmployeeFeedbacks && state.selectedEmployeeFeedbacks.length > 0;
    },
    feedbacks(state){
        return state.selectedEmployeeFeedbacks.map(feedback => (feedback));
    },
    searchResults(state){
        return state.searchResults;
    },
    hasSearchResults(state){
        return state.searchResults && state.searchResults.length >0;
    }
}