import {loadImage, refreshImage} from "@/util/image-utils";
import get from "@/util/get";
import post from "@/util/post";
import {Logger} from "@/util/Logger";
import patch from "@/util/patch";
import put from "@/util/put";

export default {

    async loginUser(context, data) {
        try {
            await post(`${process.env.VUE_APP_URL}/aaa/login`, JSON.stringify(data));
        } catch (e) {
            Logger.error("Login failed");
            throw e;
        }
    },

    async logoutUser(context) {
        const token = context.getters.token
        try {
            await get(`${process.env.VUE_APP_URL}/aaa/logout`)
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            context.commit('logoutUser', token);
        } catch (err) {
            localStorage.removeItem("token");
            context.commit('logoutUser', token);
            throw err;
        }
    },

    async registerUser(context, data) {
        return await post(`${process.env.VUE_APP_URL}/registration`, JSON.stringify(data));
    },

    async loadAuthenticatedUser({commit}) {
        const responseData = await get(`${process.env.VUE_APP_URL}/user/info`);

        if (responseData) {
            localStorage.setItem("user", JSON.stringify(responseData.responseData));

            loadImage(`/image?imageType=USER_AVATAR&iid=${responseData.responseData.uuid}`).then(
                avatar => {
                    if (avatar) {
                        commit('setAuthenticatedUserAvatar', avatar);
                    }
                }
            );

            commit('addUser', responseData.responseData);
        }
    },

    async addAvatar({commit, getters}, data) {
        const addAvatarPromise = await put(`${process.env.VUE_APP_URL}/image/avatar`, data);
        const uuid = data.get("uuid");
        refreshImage(`/image?imageType=USER_AVATAR&iid=${uuid}`);
        if (uuid === getters.user.uuid) {
            const avatar = await loadImage(`/image?imageType=USER_AVATAR&iid=${getters.user.uuid}`);
            commit('setAuthenticatedUserAvatar', avatar);
        }
        return addAvatarPromise;
    },

    async loadUserAvatar(context, data) {
        return loadImage(`/image?imageType=USER_AVATAR&iid=${data}`);
    },

    async loadAuthenticatedUserAvatar({commit, getters}) {
        const avatar = await loadImage(`/image?imageType=USER_AVATAR&iid=${getters.user.uuid}`);
        commit('setAuthenticatedUserAvatar', avatar);
    },

    async loadUserDetailInfo({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/user/info?uuid=${data}`)
        commit('setUserDetailInfo', responseData.responseData);
    },

    updateToken(context, data) {
        context.commit("updateToken", data);
    },

    async loadUserStatistics({commit}) {
        const responseData = await get(`${process.env.VUE_APP_URL}/user/attendance-statistics`);

        commit('setUserStats', responseData.responseData);
    },

    async loadUserDetailInfoForPrefill({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/user/info?uuid=${data}`)
        commit('setDataForPrefill', responseData.responseData);
    },

    async loadUserByTokenForPrefill({commit}, payload) {
        const encodedToken: string = encodeURIComponent(payload);
        const responseData = await get(`${process.env.VUE_APP_URL}/user/info/registration-token?token=${encodedToken}`);
        commit('setDataForPrefill', responseData.responseData);
        return responseData;
    },

    async loadFirstUserByTokenForPrefill({commit}, {registrationToken, email, company}) {
        const encodedToken = encodeURIComponent(registrationToken);
        const encodedEmail = encodeURIComponent(email);
        const encodedCompanyName = encodeURIComponent(company);
        const responseData = await get(`${process.env.VUE_APP_URL}/user/info/registration-token?token=${encodedToken}&email=${encodedEmail}&companyName=${encodedCompanyName}`);

        commit('setDataForPrefill', responseData.responseData);
        return responseData;
    },

    async modifyUserProfile({commit}, data) {
        const response = await patch(`${process.env.VUE_APP_URL}/user/info`, JSON.stringify(data));
        commit('setDataForPrefill', data);
        return response;
    },
}