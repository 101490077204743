import * as Mutation from './mutation-types'
import { getEventIndexById } from './utils'
import _default from "chart.js/dist/core/core.interaction";
import index = _default.modes.index;

export default {
    [Mutation.GET_EVENT](state,event){
        return state.events = event;
    },

    setBirthdays(state,event){
        return state.birthdays = event;
    },

    [Mutation.CREATE_EVENT] (state, event) {
        const date_of_event = event.start.split("T")[0];
        const indexToAdd = state.events.findIndex(item => item.date === date_of_event);

        if(indexToAdd !== -1){
            state.events[indexToAdd].events.push(event);
        }
    },
    [Mutation.UPDATE_EVENT] (state, event) {

        console.log("MUTATION EDIT", event);
        const date_of_event = event.start.split("T")[0];
        const indexToUpdate = state.events.findIndex(item => item.date === date_of_event);

        const eventToDelete = state.events[indexToUpdate].events.findIndex(item => item.id === event.id);

        const indexEvent = state.events[indexToUpdate].events.findIndex(item => item.id === event.id);

        state.events[indexToUpdate].events[indexEvent] = event;
    },
    [Mutation.DELETE_EVENT] (state, event) {
        const date_of_event = event.start.split("T")[0];
        const indexToDelete = state.events.findIndex(item => item.date === date_of_event);

        const eventToDelete = state.events[indexToDelete].events.findIndex(item => item.id === event.id);
        state.events[indexToDelete].events.splice(eventToDelete,1);
    },
    [Mutation.SET_WEEKENDS_ENABLED] (state, enabled) {
        state.weekendsVisible = enabled
    }
}
