<template>
    <div style="z-index:9998;"
        class="h-full w-full text-gray-700 dark:text-neutral-300 dark:bg-neutral-800 dark:border-neutral-900 dark:border-b-2 bg-blue-600">
        <nav role="navigation" class="hidden xl:flex 2xl:flex">
            <div class="w-full dark:bg-neutral-800 dark:border-neutral-900 dark:text-neutral-300 border-b-2 bg-white fixed top-0 z-40">
                <div class="mx-auto container px-6 py-2 xl:py-0">
                    <div class="flex items-center justify-between">
                        <div class="inset-y-0 left-0 flex items-center xl:hidden">
                            <div class="inline-flex items-center justify-center p-2 rounded-md text-gray-700
                                    hover:text-gray-100 focus:outline-none transition duration-150 ease-in-out">
                                <div class="visible xl:hidden">
                                    <svg onclick="MenuHandler(this,true)" aria-haspopup="true" aria-label="Main Menu"
                                         xmlns="http://www.w3.org/2000/svg"
                                         class="show-m-menu icon icon-tabler icon-tabler-menu" width="28" height="28"
                                         viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                                         stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z"></path>
                                        <line x1="4" y1="8" x2="20" y2="8"></line>
                                        <line x1="4" y1="16" x2="20" y2="16"></line>
                                    </svg>
                                </div>
                                <div class="hidden close-m-menu" onclick="MenuHandler(this,false)">
                                    <svg aria-label="Close" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                         viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                                         stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z"/>
                                        <line x1="18" y1="6" x2="6" y2="18"/>
                                        <line x1="6" y1="6" x2="18" y2="18"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="flex w-full sm:w-auto items-center sm:items-stretch justify-end sm:justify-start ">
                            <div class="flex items-center">
                                <h2
                                  class="hidden sm:block text-xl text-blue-600 font-bold leading-normal px-3 cursor-pointer"
                                  @click="() => $router.replace('/home')"
                                >
                                    Comporate
                                </h2>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="hidden xl:flex md:mr-6 xl:mr-16">
                                <HorizonalHeaderButton v-for="(button, index) in mainMenuButtons"
                                                       :key="index"
                                                       :text="button.text"
                                                       :url="button.url"
                                                       :icon="button.icon"/>
                            </div>
                            <div class="hidden xl:flex items-center">

                              <HeaderNotifications/>

                                <div class="ml-6 relative">
                                    <button aria-label="dropdown"
                                            class="focus:outline-none border-b-2 border-transparent dark:text-neutral-300 dark:hover:text-neutral-300 flex items-center relative"
                                            @click="dropdownHandler">
                                        <ul class="p-2 w-40 border-r bg-white absolute
                                                rounded right-0 shadow top-0 mt-12 z-50
                                                dark:bg-neutral-800 dark:border-neutral-900 dark:border-b-2 context_menu"
                                            ref="element" v-bind:class="{ hidden: isHiddenDropdown }">
                                            <li class="cursor-pointer text-sm leading-3 tracking-normal py-2  focus:outline-none">
                                                <router-link :to="'/employee/' + uuid"
                                                             class="focus:underline focus:outline-none
                                                                flex items-center dark:hover:text-neutral-300">
                                                    <i class="fa-solid fa-user"></i>
                                                    <span class="ml-2">{{$t('menu.myProfile')}}</span>
                                                </router-link>
                                            </li>
                                            <li class="cursor-pointer text-sm leading-3 tracking-normal mt-2 py-2 flex items-center focus:focus:outline-none">
                                                <router-link to="/settings-manager"
                                                             class="focus:underline focus:outline-none flex items-center dark:hover:text-neutral-300">
                                                    <i class="fa-solid fa-gear"></i>
                                                    <span class="ml-2">{{$t('menu.settings')}}</span>
                                                </router-link>
                                            </li>
                                            <li class="cursor-pointer text-sm leading-3 tracking-normal mt-2 py-2  focus:outline-none flex items-center">
                                                <router-link to="/help"
                                                             class="focus:underline focus:outline-none flex items-center dark:hover:text-neutral-300">
                                                    <i class="fa-solid fa-circle-question"></i>
                                                    <span class="ml-2">{{$t('menu.helpCenter')}}</span>
                                                </router-link>
                                            </li>
                                            <li class="cursor-pointer text-sm leading-3 tracking-normal mt-2 py-2 flex items-center focus:focus:outline-none">
                                                <a @click="logout" href="javascript:void(0)"
                                                   class="focus:underline focus:outline-none flex items-center dark:hover:text-neutral-300">
                                                    <i class="fa-solid fa-right-from-bracket"></i>
                                                    <span class="ml-2">{{$t('menu.logout')}}</span>
                                                </a>
                                            </li>
                                        </ul>


                                        <div
                                            class="cursor-pointer flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-white transition duration-150 ease-in-out">
                                            <img class="rounded-full h-8 w-8 object-cover"
                                                 :src="avatar" alt="logo"/>
                                        </div>

                                        <div class="ml-2 dark:hover:text-neutral-300">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 class="icon icon-tabler icon-tabler-chevron-down cursor-pointer dark:hover:text-neutral-300"
                                                 width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5"
                                                 stroke="currentColor" fill="none" stroke-linecap="round"
                                                 stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z"></path>
                                                <polyline points="6 9 12 15 18 9"></polyline>
                                            </svg>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <nav class="xl:hidden 2xl:hidden">
            <div class="py-2.5 border-b-2 border-gray-100 dark:border-neutral-900 border-solid px-6 w-full flex xl:hidden justify-between items-center bg-white fixed top-0 z-40 dark:bg-neutral-800">
                <div aria-label="logo" role="img" tabindex="0" class="focus:outline-none w-24">
                    <div class="flex items-center">
                        <h2 class="hidden sm:block text-blue-600 text-xl font-bold leading-normal px-3">
                            Comporate
                        </h2>
                    </div>
                </div>
                <div class="flex items-center">
                    <button id="menu" aria-label="open menu"
                            class="focus:outline-none focus:ring-2 focus:ring-gray-600 rounded-md"
                            @click="sidebarHandler(true)">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             class="icon icon-tabler icon-tabler-menu-2 dark:hover:text-neutral-300"
                             width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                             fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="4" y1="6" x2="20" y2="6"/>
                            <line x1="4" y1="12" x2="20" y2="12"/>
                            <line x1="4" y1="18" x2="20" y2="18"/>
                        </svg>
                    </button>
                </div>
            </div>
            <!--Mobile responsive sidebar-->
            <div class="absolute w-full h-full transform  z-40 xl:hidden dark:bg-neutral-800" id="mobile-nav"
                 v-bind:class="{ '-translate-x-full': !showMobileMenu }">
                <div class="bg-gray-800 opacity-50 w-full h-full dark:bg-neutral-800"
                     @click="sidebarHandler(false)"></div>
                <div
                    class="w-64 z-40 fixed overflow-y-auto z-40 top-0 bg-white shadow h-full flex-col justify-between xl:hidden pb-4 transition duration-150 ease-in-out dark:bg-neutral-800">
                    <div class="px-6 h-full">
                        <div class="flex flex-col justify-between h-full w-full">
                            <div>
                                <div class="mt-6 flex w-full items-center justify-between">
                                    <div class="flex items-center justify-between w-full">
                                        <div class="flex items-center">
                                            <h2 class="hidden sm:block text-blue-600 text-xl font-bold leading-normal px-3">
                                                Comporate
                                            </h2>
                                        </div>
                                        <button id="cross" aria-label="close menu"
                                                class="focus:outline-none focus:ring-2 rounded-md"
                                                @click="sidebarHandler(false)">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 class="icon icon-tabler icon-tabler-x" width="24"
                                                 height="24" viewBox="0 0 24 24" stroke-width="1.5"
                                                 stroke="currentColor" fill="none" stroke-linecap="round"
                                                 stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z"></path>
                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <ul class="f-m-m">
                                    <li v-for="(button, index) in mainMenuButtons" :key="index">
                                        <VerticalHeaderButton :text="button.text"
                                                              :url="button.url"
                                                              :icon="button.icon"
                                                              @closeMenu="closeMenu"
                                        />
                                    </li>
                                </ul>

                                <ul class="f-m-m mt-5">

                                    <li>
                                        <VerticalHeaderButton :url="'/employee/' + uuid"
                                                              :text="$t('menu.myProfile')"
                                                              icon="fa-solid fa-user"/>
                                    </li>

                                    <li>
                                        <VerticalHeaderButton url="/settings-manager"
                                                              :text="$t('menu.settings')"
                                                              icon="fa-solid fa-gear"/>
                                    </li>

                                </ul>
                            </div>

                            <div class="w-full pt-4">
                                <div class="flex justify-center mb-4 w-full">
                                    <div class="relative w-full">
                                        <div class="text-gray-500 absolute ml-4 inset-0 m-auto w-4 h-4">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 class="icon icon-tabler icon-tabler-search" width="16" height="16"
                                                 viewBox="0 0 24 24" stroke-width="1" stroke="#A0AEC0" fill="none"
                                                 stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z"></path>
                                                <circle cx="10" cy="10" r="7"></circle>
                                                <line x1="21" y1="21" x2="15" y2="15"></line>
                                            </svg>
                                        </div>
                                        <input
                                            class="focus:ring-2 focus:ring-gray-600 bg-gray-100 focus:outline-none rounded w-full text-sm text-gray-500 pl-10 py-2"
                                            type="text" placeholder="Search"/>
                                    </div>
                                </div>
                                <div class="border-t border-gray-300">
                                    <div class="w-full flex items-center justify-between pt-1">
                                        <div class="flex items-center">
                                            <img alt="profile-pic"
                                                 :src="avatar" tabindex="0"
                                                 class="focus:outline-none  w-8 h-8 rounded-md"/>
                                            <p tabindex="0"
                                               class="focus:outline-none text-base leading-4 ml-2">
                                                {{ userName ? userName.name : null }}
                                                {{ userName ? userName.surname : null }}</p>
                                        </div>

                                        <ul class="flex">
                                            <router-link to="/help" class="cursor-pointer">
                                                <li class="cursor-pointer pt-5 pb-3">
                                                    <div tabindex="0"
                                                         class="focus:outline-none focus:text-blue-600 w-6 h-6 md:w-8 md:h-8">
                                                        <i class="fa-solid fa-circle-question"></i>
                                                    </div>
                                                </li>
                                            </router-link>

                                            <li @click="logout" class="cursor-pointer text-red-600 pt-5 pb-3 pl-3">
                                                <div tabindex="0"
                                                     class="focus:outline-none focus:text-blue-600 w-6 h-6 md:w-8 md:h-8">
                                                    <i class="fa-solid fa-right-from-bracket"></i>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import HorizonalHeaderButton from "@/components/ui/header/HorizontalHeaderButton.vue";
import VerticalHeaderButton from "@/components/ui/header/VerticalHeaderButton.vue";
import HeaderNotifications from "@/components/ui/headerNotifications/HeaderNotifications.vue";

export default defineComponent({
    name: "TheHeader",
    components: {HeaderNotifications, VerticalHeaderButton, HorizonalHeaderButton},
    data() {
        return {
          isHiddenDropdown: true,
          showMobileMenu: false,
          isLoading: false,
          error: null,
          showMenu: true,
          currentUrl: window.location.href,
          mainMenuButtons: [
            {text: this.$i18n["t"]("menu.home"), url: "/home", icon: "fa-solid fa-house"},
            {text: this.$i18n["t"]("menu.dashboard"), url: "/dashboard", icon: "fa-solid fa-chart-line"},
            {text: this.$i18n["t"]("menu.company"), url: "/company", icon: "fa-solid fa-briefcase"},
            {text: this.$i18n["t"]("menu.messages"), url: "/chat", icon: "fa-solid fa-message"},
            {text: this.$i18n["t"]("menu.calendar"), url: "/calendar", icon: "fa-solid fa-calendar"},
            {text: this.$i18n["t"]("menu.time"), url: "/timesheet/my", icon: "fa-solid fa-clock"},
            {text: this.$i18n["t"]("menu.assets"), url: "/inventory", icon: "fa-solid fa-boxes-stacked"},
            {text: this.$i18n["t"]("menu.tasks"), url: "/tasks", icon: "fa-solid fa-list-check"}
          ]
        }
    },
    computed: {
      avatar(){
        return this.$store.getters['authenticatedUserAvatar'];
      },
        userName() {
            return this.$store.getters.user;
        },
        getAuthenticatedUser() {
            return this.$store.getters.getAuthenticatedUser.name;
        },
        authenticatedUser() {
            return this.$store.getters.getAuthenticatedUser;
        },
        uuid() {
          return this.$store.getters.userId;
        }
    },
    methods: {
        closeMenu(){
          this.showMobileMenu = false;
        },
        async logout() {
            this.isLoading = true;
            try {
                await this.$store.dispatch(`logoutUser`);
                this.$store.dispatch("util/resetShowMenu");
            } catch (error) {
                this.error = error;
            }
            this.isLoading = false;
            await this.$router.replace('/');
        },
        dropdownHandler() {
            // const single = document.getElementsByTagName("ul")[0];
            // single.classList.toggle("hidden");
            this.isHiddenDropdown = !this.isHiddenDropdown;
        },
        sidebarHandler(val) {
            this.showMobileMenu = val;
        },
        handleUrlChange() {
            // Update the currentUrl data property when the URL changes
            this.currentUrl = window.location.href;

            // You can also perform any additional actions here based on the URL change
        },
    }
});
</script>

<style scoped>
/*.router-link-exact-active {*/
/*  background-color: #fff;*/
/*  color: rgb(29 78 216);*/
/*  border-bottom: 1px solid rgb(29 78 216);*/
/*  border-bottom-width: 2px;*/
/*}*/

.router-link-active {
    border-bottom: 1px solid rgb(29 78 216);
    border-bottom-width: 2px;
}

.context_menu li a{
  border-bottom: none;
}
</style>