import getters from './getters';
import defaultWH from '../../../util/defaultWH';
import {Logger} from "@/util/Logger";

export default {
    setProjects(state, payload) {
        state.projects = payload
    },
    addProject(state, payload) {
        state.projects.push(payload);
    },

    updateProject(state, payload) {
        const indexItem = state.projects.findIndex(item => item.projectName === payload.projectName);
        state.projects[indexItem] = payload;
    },

    deleteProject(state, payload) {
        state.projects = state.projects.filter(project => project.puid !== +payload.puid);
    },

    setUpdates(state, payload) {
        state.updates = payload;
    },
    editUpdate(state, payload) {
        const itemToUpdate = state.updates.findIndex(item => item.id === +payload.id);
        state.updates[itemToUpdate] = payload;
    },
    deleteUpdate(state, payload) {
        state.updates = state.updates.filter(update => update.id !== +payload);
    },

    setSelectedUpdate(state, payload) {
        state.selectedUpdate = payload;
    },

    addUpdate(state, payload) {
        state.updates.push(payload);
    },

    setWorkingHours(state, payload) {
        state.workingHours = payload;
    },
    setOffices(state, offices) {
        state.offices = offices;
    },
    deleteOffice(state, payload) {
        state.offices = state.offices.filter(office => office.name !== +payload);
    },
    setDefaultWH(state, payload) {
        const selectedWH = getters.workingHours(state);
        state.selectedWorkingHours = selectedWH[0];
    },
    deleteWH(state, payload) {
        Logger.info("delete WH");
    },
    setAttendanceStatistics(state, payload) {
        state.attendanceStatistics = payload;
    },

    setProjectWorkingHours(state, payload) {
        state.projectWorkingHours = payload;
    },

    setProject_employees(state, payload) {
        state.project_employees = payload;
    },

    addProjectEmployeeByUUID(state, payload) {
        const foundEmployee = state.non_project_employees.find(employee => employee.uuid === payload)
        state.project_employees.push(foundEmployee);
        state.non_project_employees.splice(state.non_project_employees.indexOf(foundEmployee), 1);
    },

    fireEmployeeFromProject(state, payload) {
        const task_index = state.project_employees.map(employee => employee.uuid).indexOf(payload.userId);
        delete state.project_employees[task_index];
    },

    setNonProjectEmployees(state, payload) {
        state.non_project_employees = payload.responseData;
    },

    // PROJECT

    setDefaultProjectWH(state, payload) {
        const officeId = getters.getOfficeId(state) ?? 0;
        const selectedOfficeWH = getters.workingHours(state);
        const selectedWH = selectedOfficeWH[officeId] ? selectedOfficeWH[officeId] : null;
        state.projectWorkingHours = selectedWH;
    },

    setCompanyStats(state, payload) {
        state.company_stats = payload;
    },

    setAddedProject(state, payload) {
        state.addedProject = payload;
    },
    resetProjects(state) {
        state.projects = null;
    },
    setAddedUpdate(state, payload) {
        state.addedUpdate = payload
    },
    resetAddedUpdate(state,) {
        state.addedUpdate = null;
    }

}