import {loadImage} from "@/util/image-utils";
import get from "@/util/get";
import post from "@/util/post";
import patch from "@/util/patch";
import del from "@/util/delete";

export default {
    loadProjectLogo(context, data) {
        return loadImage(`/image?imageType=PROJECT_LOGO&iid=${data}`);
    },

    setDefaultProjectWorkingHours({commit}, data) {
        commit('setDefaultProjectWH', data);
    },

    async loadProjectWorkingHours({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/projects/business-hours?projectName=${data}`);
        commit('setProjectWorkingHours', responseData.responseData);
    },

    async saveWH(context, data) {
        await post(`${process.env.VUE_APP_URL}/company/projects/business-hours`, JSON.stringify(data));
    },

    async updateWH(context, data) {
        await patch(`${process.env.VUE_APP_URL}/company/projects/business-hours`, JSON.stringify(data));
    },

    async deleteWH({commit}, data) {
        await del(`${process.env.VUE_APP_URL}/company/projects/business-hours?projectName=${data.projectName}&weekDay=${data.weekDay}`);
        commit('deleteWH', data);
    },

    async loadSelectedProject({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/projects/full?projectName=${data}`);
        commit('setSelectedProject', responseData.responseData);
    },

    async editProject({commit, rootState}, payload) {
        await patch(`${process.env.VUE_APP_URL}/company/projects`, JSON.stringify(payload));
        commit('modifySelectedProject', payload.projectModifications);
    },
}