<script lang="ts">
import {defineComponent} from 'vue';
import Action from "@/enums/NotificationActions";

export default defineComponent({
  name: "NotificationItem",
  props: ["notification", "usersInCompany"],
  mounted() {
    console.log(this.notification);
    this.action = this.notification.action;
    this.businessUnit = this.notification.businessUnit;
  },
  data(){
    return {
      action: "",
      businessUnit: "",
      text: ""
    }
  },
  computed: {
    initiatorUser(){
      return this.usersInCompany.find((user)=> user.uuid === this.notification.initiator);
    },
    initiatorName() {
      return this.initiatorUser ? `${this.initiatorUser.name} ${this.initiatorUser.surname}` : 'Unknown User';
    },
    notificationColor(){
      switch (this.action){
        case Action.APPROVE:
          return "bg-green-400";
        case Action.ASSIGN:
          return "bg-yellow-400";
        case Action.DELETE:
          return "bg-red-500";
        case Action.UNASSIGN:
          return "bg-gray-400";
        case Action.MODIFY:
          return "bg-orange-400";
        case Action.REQUEST_APPROVE:
          return "bg-teal-400";
        case Action.REJECT:
          return "bg-red-600";
        case Action.MENTION:
          return "bg-blue-300";
        default:
          return "bg-blue-500";
      }
    }
  }
})
</script>

<template>
  <a href="#" class="flex items-start px-4 py-3 hover:bg-gray-50">
    <!-- Icon -->
    <div :class="`${notificationColor} w-10 h-10 rounded-full flex items-center justify-center text-white`">
      <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
        <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm1 13h-2v-2h2zm0-4h-2V7h2z"/>
      </svg>
    </div>

    <!-- Notification Content -->
    <div class="ml-3">
      <p class="text-sm font-medium text-gray-900 dark:text-gray-500">{{$t('notifications.title')}}</p>
      <p class="text-xs text-gray-500">
        {{initiatorName}}
        {{$t(`notifications.${this.notification.action}`)}}
        {{$t(`notifications.${this.notification.businessUnit}`)}}
      </p>
      <span class="text-xs text-gray-400">
        {{new Date(notification.timestamp).toLocaleDateString()}}
        {{new Date(notification.timestamp).toLocaleTimeString()}}
      </span>
    </div>
  </a>
</template>

<style scoped>

</style>