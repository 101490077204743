import get from "@/util/get";

const requestedImgPromises: Map<string, Promise<string>> = new Map();


export const loadImage = (path: string): Promise<string> => {
    if (requestedImgPromises.has(path)) {
        return requestedImgPromises.get(path);
    }

    const requestedImgPromise: Promise<string> = requestImage(path);

    requestedImgPromises.set(path, requestedImgPromise)

    return requestedImgPromise;
}

const requestImage = async (path): Promise<string> => {
    const response = await get(`${process.env.VUE_APP_URL}${path}`);
    if (response.responseData) {
        return 'data:image/png;base64,' + response.responseData;
    }
};

export const refreshImage = (path: string): void => {
    if (requestedImgPromises.has(path)) {
        requestedImgPromises.delete(path);
    }
}