import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
    namespaced: true,
    state() {
        return {
            toasts: [
                // {id: 1, title: "Test message 1", message: "Your changes are saved successfully", type: "success", show: true},
                // {id: 2, title: "Test message 2", message: "Your changes are saved successfully", type: "info", show: true},
                // {id: 3, title: "Test message 3", message: "Your changes are saved successfully", type: "danger", show: true}
            ]
        }
    },
    mutations,
    getters,
    actions
}