<template>
  <div @click="toggleNotifications"
       class="ml-6 relative flex items-center justify-center h-8 w-8
        cursor-pointer text-sm border-2 border-transparent rounded-full
         focus:outline-none focus:border-white transition duration-150 ease-in-out">

    <i class="fa-regular fa-bell"></i>
    <span :class="{'pulse-animation': isAnimating}" class="absolute top-0 right-0 h-4 w-4 bg-red-500 text-white text-xs rounded-full flex items-center justify-center">
      {{notificationAmount}}
    </span>
  </div>

  <div v-if="showNotifications" class="absolute right-8 top-9 w-120 bg-white shadow-lg rounded-lg dark:bg-neutral-800 dark:text-white z-50">
    <div class="p-4">
      <p class="font-bold">{{$t('notifications.header')}}</p>
      <div class="max-h-60 overflow-y-auto custom-scrollbar">
        <NotificationItem v-for="notification in notifications"
                          :key="notification.description"
                          :notification="notification"
                          :usersInCompany="usersInCompany"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, watch} from "vue";
import NotificationItem from "@/components/ui/headerNotifications/NotificationItem.vue";
import {WebSocketClient} from "@/util/WebSocketClient";

export default defineComponent({
  components: {NotificationItem},
  async mounted() {
    const user = this.$store.getters.token;
    try{
      const notifications = await this.$store.dispatch("notifications/loadNotifications", undefined, this.$store, this.$router);
      const wsClient = WebSocketClient.getWebSocket();
      wsClient.setNotificationCallback((notification) => {
        if(notification){
          this.$store.dispatch("notifications/addNotification", notification, this.$store, this.$router);
          this.$store.dispatch("employees/loadEmployees", null, this.$store, this.$router);
        }
      });

    }
    catch (e) {
      console.log(e);
    }
  },
  data(){
    return {
      showNotifications: false,
      isHiddenNotificationDropdown: true,
      isAnimating: false, // Track if the animation is active
    }
  },
  watch: {
    notificationAmount(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.triggerAnimation();
      }
    }
  },
  methods: {
    toggleNotifications(){
      this.showNotifications = !this.showNotifications;
    },
    triggerAnimation() {
      this.isAnimating = true;
      setTimeout(() => {
        this.isAnimating = false;
      }, 500); // Duration should match the CSS animation duration
    }
  },
  computed: {
    notificationAmount(){
      return this.$store.getters["notifications/notificationAmount"];
    },
    notifications(){
      return this.$store.getters["notifications/getNotifications"];
    },
    usersInCompany(){
      return this.$store.getters["employees/employees"];
    }
  }
});
</script>

<style scoped>
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #2563eb; /* Tailwind blue-600 */
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f4f8; /* Light gray background */
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.pulse-animation {
  animation: pulse 0.5s ease-in-out;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #2563eb; /* Tailwind blue-600 */
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f4f8; /* Light gray background */
}
</style>