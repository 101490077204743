export default {
    addCompanies(state, payload) {
        state.companies.push(payload);
    },
    registerCompany(state, payload){
        state.companies = payload;
    },
    isCompanyNameExists(state, payload){
        state.isCompanyNameExists = payload;
    },
    isEmailExists(state, payload){
        state.isEmailExists = payload;
    },
    isPhoneExists(state, payload){
        state.isPhoneExists = payload;
    },
    isUserPhoneExists(state, payload){
        state.isUserPhoneExists = payload;
    },
    isUserEmailExists(state, payload){
        state.isUserEmailExists = payload;
    },
    resetCompanyNameError(state){
        state.isCompanyNameExists = false;
    },
    resetEmailError(state){
        state.isEmailExists = false
    },
    resetPhoneError(state){
        state.isPhoneExists = false;
    },
    resetUserPhoneError(state){
        state.isUserPhoneExists = false;
    },
    resetUserEmailError(state){
        state.isUserEmailExists = false;
    }
}