export default {
    selectedOffice(state){
        return state.selectedOffice;
    },
    workingHours(state){
        return state.officeWorkingHours;
    },
    getOfficeEmployees(state){
        return state.officeEmployees;
    },
    getNonOfficeEmployees(state){
        return state.nonOfficeEmployees;
    },
    getFixedAssets(state){
        return state.fixedAssets;
    },
    hasFixedAssets(state){
        return state.fixedAssets && state.fixedAssets.length > 0;
    },
}