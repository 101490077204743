import actions from './actions'
import mutations from './mutations'
import getters from './getters';



export default {
    namespaced: true,
    state(){
        return {
            events: [],
            birthdays: [],
            weekendsVisible: true,
            selectedEvent: null,
        }
    },
    mutations,
    getters,
    actions
}