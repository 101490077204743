import mutations from "@/store/modules/dashboard/mutations";
import getters from "@/store/modules/dashboard/getters";
import actions from "@/store/modules/dashboard/actions";


export default {
    namespaced: true,
    state() {
        return {
            companyEmployeesAmount: 0,
            employeesOnlineAmount: 0,
            fixedAssetsAmount: 0,
            projectsAmount: 0,
            todayPaidDaysOff: 0,
            todayUnpaidDaysOff: 0,
            todayPaidSickDays: 0,
            todayUnpaidSickDays: 0,
            takenPaidDaysOffChartDates:[],
            takenPaidDaysOffChartValues:[],
            takenUnpaidDaysOffChartDates:[],
            takenUnpaidDaysOffChartValues:[],
            takenPaidSickDaysChartDates:[],
            takenPaidSickDaysChartValues:[],
            takenUnpaidSickDaysChartDates:[],
            takenUnpaidSickDaysChartValues:[],
        }
    },
    mutations,
    getters,
    actions
}