import requestHeaders from "@/util/requestHeaders";
import getAuthorizationHeaderValue from "@/util/getAuthorizationHeaderValue";
import {Logger} from "@/util/Logger";

let refreshTokenRequest: Promise<Response>;

export default async function refreshToken() {
    Logger.info("Attempt to refresh token...");
    if(!refreshTokenRequest) {
        Logger.info("Send refresh token request...");
        refreshTokenRequest = fetch(`${process.env.VUE_APP_URL}/aaa/refresh-token`, {
            headers: {
                ...requestHeaders,
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        });
        Logger.info("Wait refresh token response");
        const response: Response = await refreshTokenRequest;
        refreshTokenRequest = undefined;
        const responseToken = getAuthorizationHeaderValue(...response.headers)
        if (responseToken) {
            localStorage.setItem('token', responseToken);
            Logger.info("Authorization token updated in local storage");
        }

        if (!response.ok) {
            throw new Error(response.status + ' Unauthorized' || ` Something went wrong`);
        }
    } else {
        Logger.info("Wait refresh token response");
        await refreshTokenRequest;
    }
}