export default {
    selectedEmployee(state) {
        return state.selectedEmployee;
    },
    timesheets(state){
        return state.timesheets || [];
    },
    projects(state){
        return state.projects
    },
    tasks(state){
        return state.tasks
    },
    hasTasks(state){
        return state.tasks && state.tasks.length >0;
    },
    hasProjects(state){
        return state.projects && state.projects.length > 0;
    },
    getTimeSheet: (state) => (task,date) => {
        return state.timesheets[task].timesheets[date];
    },
    getSelectedTimeSheet: (state) =>(task,date) => {
        return state.available_timesheet[task].timesheets[date];
    },
    getSumOfProject: (state, getters) => (id) => {
        return state.timesheets[id].timesheets;
    },
    async getAllTimesheets(state, getters) {
        const all = getters.timesheets;
        return getters.timesheets;
    },
    getTotal_required_hours(state){
        return state.total_required_hours;
    },
    getTimesheetId: (state) => (timesheetId) => {
        return state.timesheets.map()
    },
    getSubordinates(state){
        return state.subordinates ? state.subordinates : null;
    },
    getLengthSubordinates(state){
        return state.subordinates ? state.subordinates.length : null;
    },
    getAvailablePeriods(state){
        return state.available_periods ? state.available_periods : null;
    },
    getAvailablePeriodsLength(state){
        return state.available_periods ? state.available_periods.length : null;
    },
    getAvailableTimesheet(state){
        return state.available_timesheet
    }
}
