import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
    namespaced: true,
    state(){
        return {
            selectedOffice: null,
            officeWorkingHours: null,
            officeEmployees: [],
            nonOfficeEmployees: [],
            fixedAssets: [],
        }
    },
    mutations,
    getters,
    actions
}