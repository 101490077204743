<template>
  <div class="toast-list">
<!--    <div-->
<!--        v-for="toast in toasts"-->
<!--        :key="toast.id"-->
<!--        class="toast"-->
<!--        :class="toast.type"-->
<!--    >-->
<!--      {{ toast.message }}-->
<!--    </div>-->
    <Toast v-for="toast in toasts" :key="toast.id"
           :id="toast.id"
           :title="toast.title"
           :message="toast.message"
           :type="toast.type"
           :show="toast.show"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Toast from "@/components/core/Toasts/Toast.vue";

export default defineComponent({
  name: "ToastsList",
  components: {
    Toast
  },
  computed:{
    toasts() {
      return this.$store.getters["toasts/allToasts"];
    }
  },
})
</script>

<style scoped>
.toast-list {
  position: fixed;
  width:400px;
  top: 50px;
  right: 50px;
  z-index: 9999;
}
</style>