import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-10 flex items-center focus:outline-none focus:border-blue-700 focus:border-b-2" }
const _hoisted_2 = { class: "w-6 h-6 md:w-8 md:h-8" }
const _hoisted_3 = {
  tabindex: "0",
  class: "text-base ml-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.url,
    class: "cursor-pointer",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeMenu')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("i", {
            class: _normalizeClass(_ctx.icon)
          }, null, 2)
        ]),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.text), 1)
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}