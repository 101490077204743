export default {
    getAccessGroups(state) {
        return state.accessGroups;
    },
    getSelectedAccessGroup(state) {
        return state.selectedAccessGroup;
    },
    getScopeTypes(state): string[] {
        return state.scopeTypes;
    },
    getTypeScopes(state): string[] {
        return state.typeScopes;
    },
    getTemplateUserAccessSettings(state) {
        return state.templateUserAccessSettings;
    }
}