import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.url,
    class: "border-b-2 border-transparent focus:border-blue-700 flex px-5 items-center py-3 text-sm leading-5 hover:bg-gray-100 focus:outline-none transition duration-150 ease-in-out dark:hover:bg-neutral-700"
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createElementVNode("i", {
          class: _normalizeClass(_ctx.icon)
        }, null, 2)
      ]),
      _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
    ]),
    _: 1
  }, 8, ["to"]))
}