export default {
    loadAllNotifications(state, payload){
        state.notifications = payload;
    },
    addNotification(state,payload){
        state.notifications.push(payload);
    },
    deleteNotification(state, payload){
        state.notifications = state.notifications.filter(notification => notification.id !== payload.id);

    }
}