import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
    namespaced: true,
    state(){
        return {
            companies: [],
            isCompanyNameExists: false,
            isCompanyEmailExists: false,
            isCompanyPhoneExists: false,
            isUserPhoneExists: false,
            isUserEmailExists: false
        }
    },
    mutations,
    getters,
    actions
}