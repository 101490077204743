export default {
    settings(state) {
        return state.settings;
    },
    getLanguage(state){
        return state.settings["USER.PREF_LANG"];
    },
    hasSettings(state) {
        return state.settings && state.settings.length > 0;
    },
    getTheme(state) {
        return state.theme;
    },

    getSettingByName: (state) => (name:string) => {
        return state.settings ? state.settings[name] : null;
    },

















    // COMPANY
    isUserAllowedToModifyCompanyData(state) {
        return state.settings && state.settings["USER.COMPANY_DATA.EDIT"]?.value || false;
    },
    isUserAllowedToConfigureCompanyPreferences(state) {
        return state.settings && state.settings["USER.COMPANY_PREFERENCES.EDIT"]?.value || false;
    },
    isUserAllowedToConfigureAccess(state) {
        return state.settings && state.settings["USER.EMPLOYEES_ACCESS.MANAGE"]?.value || false;
    },

    // OFFICES
    isUserAllowedToDeleteCompanyOffices(state) {
        return state.settings && state.settings["USER.COMPANY_OFFICES.DELETE"]?.value || false;
    },
    isUserAllowedToAddCompanyOffices(state) {
        return state.settings && state.settings["USER.COMPANY_OFFICES.ADD"]?.value || false;
    },
    isUserAllowedToEditCompanyOffices(state) {
        return state.settings && state.settings["USER.COMPANY_OFFICES.EDIT"]?.value || false;
    },
    getUserAllowedToEditCompanyOfficesScopes(state) {
        return state.settings && state.settings["USER.COMPANY_OFFICES.EDIT"].scopes;
    },

    // PROJECTS
    isUserAllowedToDeleteCompanyProjects(state) {
        return state.settings && state.settings["USER.COMPANY_PROJECTS.DELETE"]?.value || false;
    },
    isUserAllowedToAddCompanyProjects(state) {
        return state.settings && state.settings["USER.COMPANY_PROJECTS.ADD"]?.value || false;
    },
    isUserAllowedToEditCompanyProjects(state) {
        return state.settings && state.settings["USER.COMPANY_PROJECTS.EDIT"]?.value || false;
    },
    getUserAllowedToEditCompanyProjectsScopes(state) {
        return state.settings && state.settings["USER.COMPANY_PROJECTS.EDIT"].scopes;
    },
    isUserAllowedToAddCompanyProjectTasks(state) {
        return state.settings && state.settings["USER.COMPANY_PROJECT_TASKS.ADD"]?.value || false;
    },
    getUserAllowedToAddCompanyProjectTasksScopes(state) {
        return state.settings && state.settings["USER.COMPANY_PROJECT_TASKS.ADD"].scopes;
    },
    isUserAllowedToDeleteCompanyProjectTasks(state) {
        return state.settings && state.settings["USER.COMPANY_PROJECT_TASKS.DELETE"]?.value || false;
    },
    getUserAllowedToDeleteCompanyProjectTasksScopes(state) {
        return state.settings && state.settings["USER.COMPANY_PROJECT_TASKS.DELETE"].scopes;
    },
    isUserAllowedToEditCompanyProjectTasks(state) {
        return state.settings && state.settings["USER.COMPANY_PROJECT_TASKS.EDIT"]?.value || false;
    },
    getUserAllowedToEditCompanyProjectTasksScopes(state) {
        return state.settings && state.settings["USER.COMPANY_PROJECT_TASKS.EDIT"].scopes;
    },
    isUserAllowedToAddCompanyProjectEmployees(state) {
        return state.settings && state.settings["USER.COMPANY_PROJECT_EMPLOYEES.ADD"]?.value || false;
    },
    getUserAllowedToAddCompanyProjectEmployeesScopes(state) {
        return state.settings && state.settings["USER.COMPANY_PROJECT_EMPLOYEES.ADD"].scopes;
    },
    isUserAllowedToDeleteCompanyProjectEmployees(state) {
        return state.settings && state.settings["USER.COMPANY_PROJECT_EMPLOYEES.REMOVE"]?.value || false;
    },
    getUserAllowedToDeleteCompanyProjectEmployeesScopes(state) {
        return state.settings && state.settings["USER.COMPANY_PROJECT_EMPLOYEES.REMOVE"].scopes;
    },

    // DEPARTMENTS
    isUserAllowedToDeleteCompanyDepartments(state) {
        return state.settings && state.settings["USER.COMPANY_DEPARTMENTS.DELETE"]?.value || false;
    },
    isUserAllowedToAddCompanyDepartments(state) {
        return state.settings && state.settings["USER.COMPANY_DEPARTMENTS.ADD"]?.value || false;
    },
    isUserAllowedToEditCompanyDepartments(state) {
        return state.settings && state.settings["USER.COMPANY_DEPARTMENTS.EDIT"]?.value || false;
    },
    getUserAllowedToEditCompanyDepartmentsScopes(state) {
        return state.settings && state.settings["USER.COMPANY_DEPARTMENTS.EDIT"].scopes;
    },

    // ASSETS
    isUserAllowedToDeleteCompanyAssets(state) {
        return state.settings && state.settings["USER.ASSETS.DELETE"]?.value || false;
    },
    getUserAllowedToDeleteCompanyAssetsScopes(state) {
        return state.settings && state.settings["USER.ASSETS.DELETE"].scopes;
    },
    isUserAllowedToAddCompanyAssets(state) {
        return state.settings && state.settings["USER.ASSETS.ADD"]?.value || false;
    },
    getUserAllowedToAddCompanyAssetsScopes(state) {
        return state.settings && state.settings["USER.ASSETS.ADD"].scopes;
    },
    isUserAllowedToEditCompanyAssets(state) {
        return state.settings && state.settings["USER.ASSETS.EDIT"]?.value || false;
    },
    getUserAllowedToEditCompanyAssetsScopes(state) {
        return state.settings && state.settings["USER.ASSETS.EDIT"].scopes;
    },

    // TASKS
    isUserAllowedToDeleteCompanyTasks(state) {
        return state.settings && state.settings["USER.EMPLOYEE_TASKS.DELETE"]?.value || false;
    },
    getUserAllowedToDeleteCompanyTasksScopes(state) {
        return state.settings && state.settings["USER.EMPLOYEE_TASKS.DELETE"].scopes;
    },
    isUserAllowedToAddCompanyTasks(state) {
        return state.settings && state.settings["USER.EMPLOYEE_TASKS.ADD"]?.value || false;
    },
    getUserAllowedToAddCompanyTasksScopes(state) {
        return state.settings && state.settings["USER.EMPLOYEE_TASKS.ADD"].scopes;
    },
    isUserAllowedToEditCompanyTasks(state) {
        return state.settings && state.settings["USER.EMPLOYEE_TASKS.EDIT"]?.value || false;
    },
    getUserAllowedToEditCompanyTasksScopes(state) {
        return state.settings && state.settings["USER.EMPLOYEE_TASKS.EDIT"].scopes;
    },

    // CALENDAR
    isUserAllowedToAddCompanyEvents(state) {
        return state.settings && state.settings["USER.CALENDAR_EVENTS.ADD"]?.value || false;
    },
    getUserAllowedToAddCompanyEventsScopes(state) {
        return state.settings && state.settings["USER.CALENDAR_EVENTS.ADD"].scopes;
    },
    isUserAllowedToEditCompanyEvents(state) {
        return state.settings && state.settings["USER.CALENDAR_EVENTS.EDIT"]?.value || false;
    },
    getUserAllowedToEditCompanyEventsScopes(state) {
        return state.settings && state.settings["USER.CALENDAR_EVENTS.EDIT"].scopes;
    },
    isUserAllowedToDeleteCompanyEvents(state) {
        return state.settings && state.settings["USER.CALENDAR_EVENTS.DELETE"]?.value || false;
    },
    getUserAllowedToDeleteCompanyEventsScopes(state) {
        return state.settings && state.settings["USER.CALENDAR_EVENTS.DELETE"].scopes;
    },

    // EDIT USER INFO
    isUserAllowedToEditUserInfo(state) {
        return state.settings && state.settings["USER.EMPLOYEES_INFO.EDIT"]?.value || false;
    },
    getEditUserInfoScopes(state) {
        return state.settings && state.settings["USER.EMPLOYEES_INFO.EDIT"].scopes;
    },

    // ADD NEW EMPLOYEE
    isUserAllowedToAddNewEmployeeAccount(state) {
        return state.settings && state.settings["USER.COMPANY_EMPLOYEES.ADD"]?.value || false;
    },
    getAddNewEmployeeAccountScopes(state) {
        return state.settings && state.settings["USER.COMPANY_EMPLOYEES.ADD"].scopes;
    },

    // MANAGER ASSIGNMENT
    isUserAllowedToAssignManager(state) {
        return state.settings && state.settings["USER.MANAGER_ASSIGNMENT.ADD"]?.value || false;
    },
    getAssignManagerScopes(state) {
        return state.settings && state.settings["USER.MANAGER_ASSIGNMENT.ADD"].scopes;
    },
    isUserAllowedToUnassignManager(state) {
        return state.settings && state.settings["USER.MANAGER_ASSIGNMENT.DELETE"]?.value || false;
    },
    getDeleteManagerAssignmentScope(state) {
        return state.settings && state.settings["USER.MANAGER_ASSIGNMENT.DELETE"].scopes;
    },

    // EMPLOYEE FEEDBACKS
    isUserAllowedToAddEmployeeFeedbacks(state) {
        return state.settings && state.settings["USER.EMPLOYEE_FEEDBACKS.ADD"]?.value || false;
    },
    getAddEmployeeFeedbacksScopes(state) {
        return state.settings && state.settings["USER.EMPLOYEE_FEEDBACKS.ADD"].scopes;
    },
    isUserAllowedToDeleteEmployeeFeedbacks(state) {
        return state.settings && state.settings["USER.EMPLOYEE_FEEDBACKS.DELETE"]?.value || false;
    },
    getDeleteEmployeeFeedbacksScopes(state) {
        return state.settings && state.settings["USER.EMPLOYEE_FEEDBACKS.DELETE"].scopes;
    },
    isUserAllowedToEditEmployeeFeedbacks(state) {
        return state.settings && state.settings["USER.EMPLOYEE_FEEDBACKS.EDIT"]?.value || false;
    },
    getEditEmployeeFeedbacksScopes(state) {
        return state.settings && state.settings["USER.EMPLOYEE_FEEDBACKS.EDIT"].scopes;
    },

    // TIMESHEETS
    isAllowedToSeeOtherTimesheets(state) {
        return state.settings && state.settings["USER.EMPLOYEES_TIMESHEETS.SEE"]?.value || false;
    },
    getAllowedToSeeOtherTimesheetsScopes(state) {
        return state.settings && state.settings["USER.EMPLOYEES_TIMESHEETS.SEE"].scopes;
    },
    isAllowedToApproveOtherTimesheets(state) {
        return state.settings && state.settings["USER.EMPLOYEES_TIMESHEETS.APPROVE"]?.value || false;
    },
    getAllowedToApproveOtherTimesheetsScopes(state) {
        return state.settings && state.settings["USER.EMPLOYEES_TIMESHEETS.APPROVE"].scopes;
    },
    isAllowedToRejectOtherTimesheets(state) {
        return state.settings && state.settings["USER.EMPLOYEES_TIMESHEETS.REJECT"]?.value || false;
    },
    getAllowedToRejectOtherTimesheetsScopes(state) {
        return state.settings && state.settings["USER.EMPLOYEES_TIMESHEETS.REJECT"].scopes;
    },
    isAllowedToModifyOtherTimesheets(state) {
        return state.settings && state.settings["USER.EMPLOYEES_TIMESHEETS.EDIT"]?.value || false;
    },
    getAllowedToModifyOtherTimesheetsScopes(state) {
        return state.settings && state.settings["USER.EMPLOYEES_TIMESHEETS.EDIT"].scopes;
    },

    // NEWS
    isUserAllowedAddNews(state) {
        return state.settings && state.settings["USER.COMPANY_NEWS.ADD"]?.value || false;
    },
    getUserAllowedAddNewsScopes(state) {
        return state.settings && state.settings["USER.COMPANY_NEWS.ADD"].scopes;
    },
    isUserAllowedEditNews(state) {
        return state.settings && state.settings["USER.COMPANY_NEWS.EDIT"]?.value || false;
    },
    getUserAllowedEditNewsScopes(state) {
        return state.settings && state.settings["USER.COMPANY_NEWS.EDIT"].scopes;
    },
    isUserAllowedDeleteNews(state) {
        return state.settings && state.settings["USER.COMPANY_NEWS.DELETE"]?.value || false;
    },
    getUserAllowedDeleteNewsScopes(state) {
        return state.settings && state.settings["USER.COMPANY_NEWS_DELETE"].scopes;
    },
}