import {Logger} from "@/util/Logger";
import TimeSheets from "@/components/ui/timesheets/TimeSheets.vue";

export default {
    setSelectedEmployee(state, payload){
        state.selectedEmployee = payload;
    },
    getTimesheets(state, payload){
        state.timesheets = payload;
    },
    addTaskToTimesheets(state, payload){
        state.timesheets[payload.taskId] = {...payload}
    },
    addTimesheet(state, payload){
        payload.forEach(timeSheet => {
            state.timesheets[timeSheet.taskId].timesheets[timeSheet.date] = timeSheet;
        });
    },
    updateTimesheetTime(state, payload){
        payload.forEach(timeSheet => {
            state.timesheets[timeSheet.taskId].timesheets[timeSheet.date] = timeSheet;
        });
    },
    setAvailableProjects(state, payload){
        state.projects = payload.responseData;
    },
    setProjectTask(state, payload){
        state.tasks = payload.responseData;
    },
    // set Total Required Hours
    setTRH(state,payload){
        state.total_required_hours = payload;
    },
    // delete row of table for provided task
    removeTimesheetTask(state,payload){
        delete state.timesheets[payload];
    },
    resetTimesheets(state){
        state.timesheets = null;
    },

    setSubordinates(state, payload){
        state.subordinates = payload;
    },

    setAvailablePeriods(state, payload){
        state.available_periods = payload;
    },
    setAvailableTimesheet(state, payload){
        state.available_timesheet = payload;
    }
}