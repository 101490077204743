import get from "@/util/get";
import post from "@/util/post";
import {refreshImage} from "@/util/image-utils";
import put from "@/util/put";
import del from "@/util/delete";

export default {
    async registerEmployee({commit}, data) {
        const responseData = await post(`${process.env.VUE_APP_URL}/new-employee`, JSON.stringify(data));
        data.uuid = responseData.responseData;
        commit('registerEmployee', data);
        return responseData;
    },
    async loadEmployees({commit}) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/all-employees`);
        commit('loadEmployees', responseData.responseData);
    },
    async getEmployeeAssignedProjects(context, uuid?: number) {
        return await get(`${process.env.VUE_APP_URL}/company/employee/projects/history${uuid ? ('?uuid=' + uuid) : ''}`);
    },
     async loadSelectedEmployee({commit}, data) {
        const {payload} = data;
        const params = data.type ? `token=${payload}` : `uuid=${payload}`;
        try {
            commit('setLoading', true);
            const responseData = await get(`${process.env.VUE_APP_URL}/user/info?${params}`);
            commit('loadSelectedEmployee', responseData.responseData);
            return responseData;
        } catch (error) {
            commit('setError', error);
            throw error;
        } finally {
            commit('setLoading', false);
        }
    },
    async addComment({commit}, data) {
        await post(`${process.env.VUE_APP_URL}/feedbacks/new`, JSON.stringify(data));
        commit('addComment', data);
    },
    async loadFeedbacks({commit}, uuid) {
        const responseData = await get(`${process.env.VUE_APP_URL}/feedbacks?uuid=${uuid}`);
        commit('setFeedbacks', responseData.responseData);
    },
    async loadSearchResults({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/find?value=${data}`);
        commit('loadSearchResults', responseData);
    },

    async resetSearchResults(context) {
        context.commit('resetSearchResults');
    },
    async resetFeedbacks(context) {
        context.commit('resetFeedbacks');
    },
    async addAvatar(context, data) {
        const addAvatarPromise = await put(`${process.env.VUE_APP_URL}/image/avatar`, data);
        refreshImage(`/image?imageType=USER_AVATAR&iid=${data.get("uuid")}`);
        return addAvatarPromise;
    },
    async addImage(context, data) {
        return await put(`${process.env.VUE_APP_URL}/image`, data);
    },
    async getPicture(context, data) {
        return await get(`${process.env.VUE_APP_URL}/image?iid=${data}`);
    },
    resetAddedEmployee({commit}) {
        commit('resetAddedEmployee');
    },
    async addManager({commit}, data){
        const requestBody = {uuid: data.uuid, managerUuid: data.manager.uuid}
        await post(`${process.env.VUE_APP_URL}/user/manager`, JSON.stringify(requestBody));
        commit('addManager', data.manager);
    },
    async removeManager({commit}, data) {
        await del(`${process.env.VUE_APP_URL}/user/manager?uuid=${data.uuid}&managerUuid=${data.managerUUID}`);
        commit('removeManager', data);
    }
}