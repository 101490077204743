import SockJS from "sockjs-client";
import {CompatClient, Stomp} from "@stomp/stompjs";
import IMessage from "@/types/IMessage";
import {Logger} from "@/util/Logger";
import refreshToken from "@/util/refreshToken";


export class WebSocketClient {
    private readonly WS_PATH: string = process.env.VUE_APP_URL + "/hrh-ws";
    private readonly TIMEOUT: number = 5 * 60 * 1000;
    private client: CompatClient;
    private reconnectAttempts: number;
    private notificationCallback: (msg: any) => void;

    private constructor() {
        this.reconnectAttempts = 0;
        this.connect();
    }

    public readonly connect = () => {
        // const token = localStorage.getItem("token");
        // if (!token) {
        //     // TODO: add the way to start web socket when token appears
        //     return;
        // }

        const sock: WebSocket = new SockJS(this.WS_PATH);
        this.client = Stomp.over(sock);

        this.client.connect(
            /* connect headers */{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            /* onConnect */ () => {
                this.reconnectAttempts = 0;
                this.client.subscribe(
                    "/user/topic/messages",
                    (message) => {
                        const msg = JSON.parse(message.body);
                        window.postMessage({type: 'chat', data: msg}, '*');
                    },
                    {'Authorization': localStorage.getItem('token')}
                );

                this.client.subscribe(
                    "/user/topic/notifications",
                    (message) => {
                        if (this.notificationCallback) {
                            const notification = JSON.parse(message.body);
                            this.notificationCallback(notification);
                        }
                    },
                    { Authorization: localStorage.getItem("token") }
                );
            },
            /* onStompError */ message => {
                Logger.error(message);
            },
            /* onWebSocketClose */ async (e) => {
                Logger.error("WebSocket close message:", e);

                if (this.reconnectAttempts === 3) {
                    try {
                        try {
                            await refreshToken();
                        } catch (e) {
                            Logger.error("Failed to establish connection with WebSocket.")
                            if (e === "401 Unauthorized") {
                                localStorage.removeItem("token");
                                localStorage.removeItem("user");
                            }
                            if (location.pathname !== "/") {
                                location.pathname = "/";
                            }
                        }
                        this.reconnectAttempts++;
                        this.connect();
                        return;
                    } catch (e) {
                        console.error(e);
                    }
                }
                this.reconnectAttempts++;
                this.connect();
            }
        );

        setTimeout(this.disconnect, this.TIMEOUT);
    }

    private readonly disconnect = () => {
        this.client?.disconnect();
        this.client = null;
        setTimeout(this.disconnect, this.TIMEOUT);
    }

    public readonly sendMessage = (message: IMessage) => {
        this.client.send('/app/chat', {'Authorization': localStorage.getItem('token')}, JSON.stringify(message));
    }

    public readonly subscribeNotifications = () => {
        this.client.subscribe(
            "/app/notifications",
            (message) => {
                if (message.body) {
                    console.log("Received notification: ", message.body);
                    const notification = JSON.parse(message.body);
                    alert(notification);
                }
            },
            { Authorization: localStorage.getItem("token") }
        );
    };

    public setNotificationCallback = (callback: (msg: any) => void) => {
        this.notificationCallback = callback;
    };

    private static instance: WebSocketClient;

    private static initializeWebSocket = (): void => {
        WebSocketClient.instance = new WebSocketClient();
    }

    public static getWebSocket = (): WebSocketClient => {
        if (!WebSocketClient.instance) {
            WebSocketClient.initializeWebSocket();
        }
        return WebSocketClient.instance;
    }
}