import moment from "moment/moment";

export function plusMonth(strDate: Date): string {
    const date = new Date(strDate);
    return moment(date).add(1, 'month').format("YYYY-MM-DD");
}

export function minusMonth(strDate: Date): string {
    const date = new Date(strDate);
    return moment(date).subtract(1, 'month').format("YYYY-MM-DD");
}

export function formatDateTime(inputDate: string): string {
    const date = new Date(inputDate);
    return moment(date).format('D MMM YYYY HH:mm');
}

export function parseDate(date: Date): string {
    // const monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // const month = date.getMonth() + 1;
    // const day = date.getDate();
    // return `${day} ${monthShortNames[month - 1]} ${date.getFullYear()}`
    // return `${date.getFullYear()}-${month < 10 ? ('0' + month) : month}-${day < 10 ? ('0' + day) : day}`

    return moment(date).format('D MMM YYYY');
}

export function parseTime(date: Date): string {
    return moment(date).format('HH:mm');
    // return ((date.getHours() < 10 ? "0" : "") + date.getHours()) + ":" + ((date.getMinutes() < 10 ? "0" : "") + date.getMinutes());
}

export function getTodayISODate() {
    const now = new Date();
    return moment(now).format("YYYY-MM-DD");
}

export function getMonthNameAndYear(date: Date) {
    return moment(date).startOf('month').format('MMMM YYYY');
}

export function getWeekdayName(day: number) {
    return moment().weekday(day).format("ddd");
}

export function getFirstDayOfTheMonth(date: Date) {
    return moment(date).startOf('month').format('YYYY-MM-DD');
}


export function getLastDayOfTheMonth(date: Date) {
    return moment(date).endOf('month').format('YYYY-MM-DD');
}

export function getISODateTime(date: Date): string {
    const offset = date.getTimezoneOffset();
    return moment(date, "YYYY-MM-DDTHH:mm:ss").add(offset, "minutes").format("YYYY-MM-DDTHH:mm:ss");
}

export function getISODateTimeWithMills(date: Date):string{
    const offset = date.getTimezoneOffset();
    return moment(date, "YYYY-MM-DDTHH:mm:ss").add(offset, "minutes").format("YYYY-MM-DDTHH:mm:ss.SSSSSS");
}

export function localizeISODateTime(date: Date): string {
    const offset = date.getTimezoneOffset();
    return moment(date, "YYYY-MM-DDTHH:mm:ss").subtract(offset, "minutes").format("YYYY-MM-DDTHH:mm:ss");
}

export function getISODate(date: Date): string {
    return moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
}



