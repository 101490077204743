import get from "@/util/get";
import post from "@/util/post";
import patch from "@/util/patch";
import del from "@/util/delete";

export default {
    async loadSelectedOffice({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/offices?name=${data}`);
        commit('setSelectedOffice', responseData.responseData);
    },
    async editOffice({commit, rootState}, payload) {
        await patch(`${process.env.VUE_APP_URL}/company/offices`, JSON.stringify(payload));
        commit('modifySelectedOffice', payload.officeModifications);
    },
    async loadOfficeWorkingHours({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/offices/business-hours?officeName=${data}`);
        commit('setOfficeWorkingHours', responseData.responseData);
    },
    async saveWH(context, data) {
        await post(`${process.env.VUE_APP_URL}/company/offices/business-hours`, JSON.stringify(data));
    },
    async updateWH(context, data) {
        await patch(`${process.env.VUE_APP_URL}/company/offices/business-hours`, JSON.stringify(data));
    },
    async deleteWH({commit}, data) {
        await del(`${process.env.VUE_APP_URL}/company/offices/business-hours?officeName=${data.officeName}&weekDay=${data.weekDay}`);
        commit('deleteWH', data);
    },
    async loadOfficeEmployees({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/offices/employees?officeName=${data}`);
        commit('setOfficeEmployees', responseData.responseData);
    },
    async deleteEmployeeFromOffice({commit}, data) {
        await del(`${process.env.VUE_APP_URL}/company/offices/employees/${data}`);
        commit('deleteEmployeeFromOffice', data);
    },
    async loadNonOfficeEmployees({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/offices/all-non-office-employees?officeName=${data}`);
        commit('setNonOfficeEmployees', responseData);
    },
    async addEmployeeToOffice({commit}, data) {
        await post(`${process.env.VUE_APP_URL}/company/offices/employees/${data.uuid}`, data.office);
        commit("addOfficeEmployeeByUUID", data.uuid)
    },
    async loadFixedAssets({commit}, data)
    {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/offices/fixed-assets?officeName=${data}`);
        commit('setFixedAssets', responseData.responseData);
    }
}