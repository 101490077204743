<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
    name: "HorizonalHeaderButton",
    props: ["text", "url", "icon"]
})
</script>

<template>
    <router-link :to="url" class="border-b-2 border-transparent focus:border-blue-700 flex px-5 items-center
                                py-3 text-sm leading-5 hover:bg-gray-100
                                focus:outline-none transition duration-150 ease-in-out dark:hover:bg-neutral-700">
        <span class="mr-2"><i :class="icon"></i></span>
        {{text}}
    </router-link>
</template>

<style scoped>

</style>