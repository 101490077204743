import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
    namespaced: true,
    state(){
        return {
            employees: [],
            status: '',
            selectedEmployee: null,
            selectedEmployeeFeedbacks:[],
            comment: '',
            searchResults: [],
            loading: false,
            error: null
        }
    },
    mutations,
    getters,
    actions
}