import {computed, createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'
import './index.css'
import '@fortawesome/fontawesome-free/js/all.js';
import {clickOutside} from "@/util/directives/click-outside";
import { createI18n, useI18n } from 'vue-i18n';
import {defaultLocale} from "@/i18n";

import en from "./i18n/en.json";
import ru from "./i18n/ru.json";
import ro from "./i18n/ro.json";

const i18n = createI18n({
    legacy: true,
    locale: JSON.parse(localStorage.getItem("language")) || "en",
    fallbackLocale: 'en',
    messages: {en, ru, ro}
});

const app = createApp(App);

app.directive("click-outside", clickOutside);

app.use(i18n).use(store).use(router).mount('#app');

// const someValue = computed(() => store.getters["settings/settings"]);
// console.log(someValue);
