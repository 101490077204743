enum Action {
    ASSIGN = "ASSIGN",
    UNASSIGN = "UNASSIGN",
    MODIFY = "MODIFY",
    REQUEST_APPROVE = "REQUEST_APPROVE",
    APPROVE = "APPROVE",
    REJECT = "REJECT",
    MENTION = "MENTION",
    DELETE = "DELETE"
}

export default Action;

