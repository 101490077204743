import post from "@/util/post";
import {Logger} from "@/util/Logger";

export default {
     setShowMenu(context, data) {
        context.commit('setShowMenu',data)
     },
    setCurrentRoute(context,data){
        context.commit('setCurrentRoute',data)
    },
    resetShowMenu(context){
         context.commit("resetShowMenu");
    }
}