import mutations from "@/store/modules/employeetasks/mutations";
import getters from "@/store/modules/employeetasks/getters";
import actions from "@/store/modules/employeetasks/actions";

export default {
    namespaced: true,
    state(){
        return {
            tasks: []
        }
    },
    mutations,
    getters,
    actions
}