import get from "@/util/get";
import post from "@/util/post";

export default {
    async loadCompanies(context) {
        const responseData = await get(`${process.env.VUE_APP_URL}/all-confirmed-companies`);
        context.commit('registerCompany', responseData.responseData);
    },

    async registerCompany(context, data) {
        await post(`${process.env.VUE_APP_URL}/company/registration`, JSON.stringify(data));
        context.commit('registerCompany')
    },

    async confirmCompanyEmail(context, data) {
        const response = await post(`${process.env.VUE_APP_URL}/company/registration/email/confirm`, JSON.stringify(data));
        if(response.status !== 200){
            throw new Error(response.status);
        }
        return response;
    },

    async verifyCompanyName(context, data) {
        context.commit(
            'isCompanyNameExists',
            await get(`${process.env.VUE_APP_URL}/company/registration/such-name-exists?name=${data}`)
        );
    },
    async verifyEmail(context, data) {
        context.commit(
            'isEmailExists',
            await get(`${process.env.VUE_APP_URL}/company/registration/such-email-exists?email=${data}`)
        );
    },
    async verifyPhone(context, data) {
        context.commit(
            'isPhoneExists',
            await get(`${process.env.VUE_APP_URL}/company/registration/such-phone-exists?phone=${data}`)
        );
    },
    async verifyUserEmail(context, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/registration/such-email-exists?email=${data}`);
        context.commit('isUserEmailExists', responseData);
    },
    async verifyUserPhone(context, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/registration/such-phone-exists?phone=${data}`);
        context.commit('isUserPhoneExists', responseData);
    },

    // RESET ERRORS
    resetCompanyNameError({commit}){
        commit('resetCompanyNameError');
    },
    resetEmailError({commit}){
        commit('resetEmailError');
    },
    resetPhoneError({commit}){
        commit('resetPhoneError');
    },
    resetUserPhoneError({commit}){
        commit('resetUserPhoneError')
    },
    resetUserEmailError({commit}){
        commit('resetUserEmailError')
    }
}