import post from "@/util/post";
import get from "@/util/get";
import patch from "@/util/patch";
import del from "@/util/delete";

export default {
    async loadAllActiveTasks({commit}) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/employee/tasks`);
        commit('setTasks', responseData.responseData);
    },
    async loadAllEmployeeRelatedActiveTasks({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/employee/tasks?uuid=${data}`);
        commit('setTasks', responseData.responseData);
    },
    async addTask({commit}, data) {
        const responseData = await post(`${process.env.VUE_APP_URL}/company/employee/tasks`, JSON.stringify(data));
        commit('addTask', responseData.responseData);
    },
    async editTask({commit}, data) {
        const responseData = await patch(`${process.env.VUE_APP_URL}/company/employee/tasks`, JSON.stringify(data));
        commit('updateTask', responseData.responseData);
    },
    async deleteTask({commit}, data) {
        await del(`${process.env.VUE_APP_URL}/company/employee/tasks/${data}`);
        commit('deleteTask', data);
    },
    async clearStorage({commit}) {
        commit('deleteAllFromTasks');
    },
}