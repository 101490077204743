import getAuthorizationHeaderValue from "@/util/getAuthorizationHeaderValue";

export default function manageAuthorizationToken(response): void {
    if (response.status === 401) {
        throw new Error("401 Unauthorized")
    } else {
        const responseTokenValue = getAuthorizationHeaderValue(...response.headers);
        if (responseTokenValue) {
            localStorage.setItem('token', responseTokenValue);
        }
    }
}