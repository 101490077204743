import get from "@/util/get";
import {WebSocketClient} from "@/util/WebSocketClient";

export default {
    async loadNotifications({commit}, data){
        const responseData = await get(`${process.env.VUE_APP_URL}/notifications`);
        commit('loadAllNotifications', responseData.responseData);
    },

    addNotification({commit}, payload) {
        commit('addNotification', payload);
    },

    subscribeToNotifications(){
        WebSocketClient.getWebSocket().subscribeNotifications();
    }
}