export default {
    setInventory(state, payload){
        state.inventory = payload;
    },
    addToInventory(state,payload){
        state.inventory.push(payload);
    },
    editItem(state,payload){
        const indexItem = state.inventory.findIndex(item => item.created === payload.created);
        state.inventory[indexItem] = payload;
    },
    deleteFromInventory(state,payload){
        const index = state.inventory.findIndex(item => item.created === payload.created);
        state.inventory.splice(index,1);
    },
    deleteAllFromInventory(state){
        state.inventory = [];
    }
}