export default {
    projects(state) {
        return state.projects;
    },
    hasProjects(state) {
        return state.projects && state.projects.length > 0;
    },
    workingHours(state) {
        return state.workingHours
    },
    hasWorkingHours(state) {
        return state.workingHours && state.workingHours.length > 0;
    },

    availableBusinessUnits(state){
        return state.availableBusinessUnits;
    },

    offices(state){
        return state.offices;
    },
    hasOffices(state){
        return state.offices && state.offices.length > 0;
    },
    getOfficeId(state){
        return state.selectedOffice ? state.selectedOffice.id : null
    },

    getSelectedWorkingHours(state){
        return state.selectedWorkingHours;
    },
    getUserOfficeName(state, getters, rootState, rootGetters ){
        const userOfficeName = rootGetters.getUserOffice;
        return state.offices.filter(office => {
            if (office.name === userOfficeName) {
                return office.name;
            }
        });
    },
    getAttendanceStatistics(state){
        return state.attendanceStatistics;
    },

    getAttendanceStatisticsPaidOff(state){
        return state.attendanceStatistics ? state.attendanceStatistics["paidDayOffsAmount"] : 0;
    },

    getAttendanceStatisticsPaidSick(state){
        return state.attendanceStatistics ? state.attendanceStatistics["paidSickDaysAmount"] : 0;
    },

    getAttendanceStatisticsUnPaidOff(state){
        return state.attendanceStatistics ? state.attendanceStatistics["paidSickDaysAmount"] : 0;
    },

    getAttendanceStatisticsUnPaidSick(state){
        return state.attendanceStatistics ? state.attendanceStatistics["unpaidSickDaysAmount"] : 0;
    },

    getWfhEmployeesAmount(state){
        return state.attendanceStatistics ? state.attendanceStatistics["wfhEmployeesAmount"] : 0;
    },

    getWfoEmployeesAmount(state){
        return state.attendanceStatistics ? state.attendanceStatistics["wfoEmployeesAmount"] : 0;
    },

    getProjectWorkingHours(state){
        return state.projectWorkingHours;
    },

    getAddedProject(state){
        return state.addedProject;
    },

     getProjectEmployees(state){
        return state.project_employees;
    },

    hasProjectEmployees(state) {
        return state.project_employees && state.project_employees.length > 0;
    },

    getNonProjectEmployees(state){
        return state.non_project_employees;
    },

    hasNonProjectEmployees(state) {
        return state.non_project_employees && state.non_project_employees.length > 0;
    },

    updates(state){
        return state.updates;
    },

    selectedUpdate(state){
        return state.selectedUpdate;
    },

    hasUpdates(state){
        return state.updates && state.updates.length > 0;
    },

    companyStats(state){
        return state.company_stats
    },

    // paidDaysOff
    // sickDays
    // unpaidDaysOff

    companyStatsPaidDaysOff(state){
        return state.company_stats  ? state.company_stats.paidDaysOff : '-';
    },
    companyStatsSickDays(state){
        return state.company_stats ? state.company_stats.sickDays : '-';
    },
    companyStatsUnpaidDaysOff(state){
        return  state.company_stats ? state.company_stats.unpaidDaysOff: '-';
    }
}