import requestHeaders from "@/util/requestHeaders";
import manageAuthorizationToken from "@/util/manage-authorization-token";
import authHeader from "@/util/auth-header";
import {getResponseData, manageResponseException} from "@/util/response-manager";

export default async function put(url, body) {
    try {
        return await sendRequest(url, body);
    } catch (error) {
        return await manageResponseException(error, () => sendRequest(url, body));
    }

}

const sendRequest = async (url, body) => {
    const headers = {
        ...requestHeaders,
        ...authHeader()
    };

    if(!(body instanceof FormData)){
        headers['Content-Type'] = 'application/json';
    }
    const response = await fetch(url, {
        method: 'PUT',
        headers: headers,
        body
    });

    manageAuthorizationToken(response);

    return await getResponseData(response);
}