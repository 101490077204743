export default {
    loadToasts(state, payload){
        state.toasts = payload;
    },
    addToast(state, payload){
        state.toasts.push(payload);
    },
    removeToast(state, payload){
        state.toasts = state.toasts.filter(toast =>  toast.id !== payload);
    }
}