export default {
    setTasks(state, payload){
        state.tasks = payload;
    },

    addTask(state,payload){
        state.tasks.push(payload);
    },

    deleteTask(state, payload){
        const task_index = state.tasks.map(task => task.tuid).indexOf(payload.tuid);
        state.tasks.splice(task_index,1);
    },

    updateTask(state, payload){
        const task_index = state.tasks.map(task => task.tuid).indexOf(payload.tuid);
        state.tasks[task_index] = payload;
    }
}