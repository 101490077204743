export default {
    setSelectedOffice(state, payload) {
        state.selectedOffice = payload;
    },
    deleteOffice(state, payload) {
        state.deleteOffice = payload;
    },
    modifySelectedOffice(state, payload) {
        state.selectedOffice.name = payload.name;
        state.selectedOffice.address = payload.address;
        state.selectedOffice.countryName = payload.countryName;
        state.selectedOffice.countryId = payload.countryId;
        state.selectedOffice.city = payload.city;
        state.selectedOffice.description = payload.description;
    },
    setOfficeWorkingHours(state, payload) {
        state.officeWorkingHours = payload;
    },
    deleteWH(state, payload) {
        state.officeWorkingHours.forEach(day => {
            if(day.weekDay === payload.weekDay) {
                day.openHours = null;
                day.closeHours = null;
                day.requiredWorkHours = null;
                day.officeName = null;
            }
        });
    },
    setOfficeEmployees(state, payload){
        state.officeEmployees = payload;
    },
    deleteEmployeeFromOffice(state, uuid){
        state.officeEmployees = state.officeEmployees.filter(employee => employee.uuid !== uuid);
    },
    setNonOfficeEmployees(state, payload){
        state.nonOfficeEmployees = payload.responseData;
    },
    addOfficeEmployeeByUUID(state, payload){
        const foundEmployee = state.nonOfficeEmployees.find(employee => employee.uuid === payload)
        state.officeEmployees.push(foundEmployee);
        state.nonOfficeEmployees.splice(state.nonOfficeEmployees.indexOf(foundEmployee), 1);
    },
    setFixedAssets(state, payload){
        state.fixedAssets = payload;
    },
}