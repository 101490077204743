export default {
    setLoading(state, payload) {
        state.loading = payload
    },
    setSuccessfulApproved(state, payload) {
        state.successfulApproved = payload
    },
    setError(state, payload) {
        state.error = payload
    }
}