export default {
    addUser(state, payload) {
        state.user = payload;
        state.token = localStorage.getItem('token');
        state.status = "success";
    },
    logoutUser(state) {
        state.user = null;
        state.token = null;
    },
    setAuthenticatedUserAvatar(state, payload) {
        state.authenticatedUserAvatar = payload;
    },
    setUserDetailInfo(state, payload) {
        state.detailInfo = payload;
    },
    updateToken(state, payload) {
        state.token = payload;
    },

    setUserStats(state, payload) {
        state.userStats = payload;
    },
    setDataForPrefill(state, payload) {
        state.dataForPrefill = payload;
    },
    setLoading(state, payload) {
        state.loading = payload
    },
    setSuccess(state, payload) {
        state.success = payload
    },
    setError(state, payload) {
        state.error = payload
    }
}