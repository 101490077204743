import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
    namespaced: true,
    state(){
        return {
            project_working_hours: null,
            selectedProject: null
        }
    },
    mutations,
    getters,
    actions
}