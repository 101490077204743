import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

const image = "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";

export default {
    namespaced: true,
    state(){
        return {
            employees: [],
            projects: [],
            searchResults: [],
            updates: [],
            workingHours: {},
            offices: [],
            selectedOffice: null,
            selectedWorkingHours: null,
            attendanceStatistics: {},
            project_employees: [],
            non_project_employees: [],
            projectWorkingHours: [],
            // company_stats: {},
            addedProject: null,
            addedUpdate: null,
            company_stats: null,
            selectedUpdate: null,
                // paidDaysOff: [
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:2, img: image},
                //     {name:"test", surname:"one",uuid:3, img: image},
                //     {name:"test", surname:"one",uuid:4, img: image},
                //     {name:"test", surname:"one",uuid:5, img: image},
                //
                //     {name:"test", surname:"one",uuid:6, img: image},
                //     {name:"test", surname:"one",uuid:7, img: image},
                // ],
                // sickDays: [
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                // ],
                // unpaidDaysOff: [
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                //     {name:"test", surname:"one",uuid:1, img: image},
                // ]
            // }
            availableBusinessUnits: [
                "company",
                "offices",
                "projects",
                "departments",
                "employees",
                "dashboard",
                "tasks",
                "assets",
                "calendar",
                "messages",
                "timesheets",
                "newsfeed"
            ]
        }

    },
    mutations,
    getters,
    actions
}