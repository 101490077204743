import actions from './actions'
import mutations from './mutations'
import getters from './getters';

export default {
    namespaced:true,
    state(){
        return {
            loading: false,
            successfulApproved: false,
            error: null
        }
    },
    mutations,
    getters,
    actions
}