import post from "@/util/post";
import get from "@/util/get";
import patch from "@/util/patch";
import del from "@/util/delete";

export default {
    async getStructuredTimesheets({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/timesheets/structured?start=${data.start}&end=${data.end}`);
        console.log("getStructuredTimesheets",responseData.responseData);
        commit('getTimesheets', responseData.responseData);
    },
    async getStructuredTimesheetsByUser({commit}, data) {
        const responseData = await get(`${process.env.VUE_APP_URL}/timesheets/structured?uuid=${data.uuid}&start=${data.start}&end=${data.end}`);
        commit('setAvailableTimesheet', responseData.responseData);
    },
    async getAllTimesheets({commit}) {
        const responseData = await get(`${process.env.VUE_APP_URL}/timesheets`);
        commit('getTimesheets', responseData.responseData);
    },
    async addTimesheet({commit}, data) {
        await post(`${process.env.VUE_APP_URL}/timesheets`, JSON.stringify(data));
        commit('addTimesheet', data);
    },
    async loadEmployeeAssignedProjects({commit}, uuid?: number) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/employee/projects${uuid ? ('?uuid=' + uuid) : ''}`);
        commit('setAvailableProjects', responseData,responseData);
    },
    async getProjectTask({commit}, projectId) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/projects/tasks?projectName=${projectId}`);
        commit('setProjectTask', responseData);
    },
    async loadSelectedEmployeeShortDescription({commit}, uuid) {
        const responseData = await get(`${process.env.VUE_APP_URL}/user/info/short?uuid=${uuid}`);
        commit('setSelectedEmployee', responseData.responseData);
    },
    async addTimeSheetLocal({commit}, data) {
        commit('addTaskToTimesheets', data);
    },
    async updateTimesheetTime({commit}, data) {
        commit('updateTimesheetTime', data);
    },
    async getTotalRequiredHours({commit}) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/employee/projects/agregated-required-working-hours-per-week`);
        commit('setTRH', responseData.responseData);
    },
    async deleteTimesheet({commit}, data) {
        await del(`${process.env.VUE_APP_URL}/timesheets?uuid=${data.userId}&tuid=${data.taskId}&startDate=${data.startDate}&endDate=${data.endDate}`);
        commit('removeTimesheetTask', data.taskId);
    },
    resetTimesheets({commit}) {
        commit("resetTimesheets");
    },

    async updateTimeSheet({commit}, data) {
        await patch(`${process.env.VUE_APP_URL}/timesheets`, JSON.stringify(data));
        commit('updateTimesheetTime', data);
    },

    async getSubordinates({commit}, uuid) {
        const responseData = await get(`${process.env.VUE_APP_URL}/company/management-relationships/subordinates?uuid=${uuid}`);
        commit('setSubordinates', responseData.responseData);
    },

    async getAvailablePeriods({commit}, uuid) {
        const responseData = await get(`${process.env.VUE_APP_URL}/timesheets/available-periods?uuid=${uuid}`);
        commit('setAvailablePeriods', responseData.responseData);
    },

    async approveTimesheet({commit}, data) {
        const responseData = await patch(`${process.env.VUE_APP_URL}/timesheets/approve`, JSON.stringify(data));
        commit("setAvailableTimesheet", responseData.responseData);
    },

    async rejectTimesheet({commit}, data) {
        const responseData = await patch(`${process.env.VUE_APP_URL}/timesheets/reject`, JSON.stringify(data));
        commit("setAvailableTimesheet", responseData.responseData);
    },

}