import {parseDate} from "@/util/dateFormatter";

export default {
    setCompanyStatistics(state, payload) {
        const takenPaidDaysOffChartDates = [];
        const takenPaidDaysOffChartValues = [];

        payload['paidDaysOff'].forEach(entry => {
            takenPaidDaysOffChartDates.push(parseDate(new Date(entry.date)));
            takenPaidDaysOffChartValues.push(entry.amount);
        })

        state.takenPaidDaysOffChartDates = takenPaidDaysOffChartDates;
        state.takenPaidDaysOffChartValues = takenPaidDaysOffChartValues;

        const takenUnpaidDaysOffChartDates = []
        const takenUnpaidDaysOffChartValues = []

        payload['unpaidDaysOff'].forEach(entry => {
            takenUnpaidDaysOffChartDates.push(parseDate(new Date(entry.date)));
            takenUnpaidDaysOffChartValues.push(entry.amount);
        })

        state.takenUnpaidDaysOffChartDates = takenUnpaidDaysOffChartDates;
        state.takenUnpaidDaysOffChartValues = takenUnpaidDaysOffChartValues;


        const takenPaidSickDaysChartDates = []
        const takenPaidSickDaysChartValues = []

        payload['paidSickDays'].forEach(entry => {
            takenPaidSickDaysChartDates.push(parseDate(new Date(entry.date)));
            takenPaidSickDaysChartValues.push(entry.amount);
        })

        state.takenPaidSickDaysChartDates = takenPaidSickDaysChartDates;
        state.takenPaidSickDaysChartValues = takenPaidSickDaysChartValues;

        const takenUnpaidSickDaysChartDates = []
        const takenUnpaidSickDaysChartValues = []

        payload['unpaidSickDays'].forEach(entry => {
            takenUnpaidSickDaysChartDates.push(parseDate(new Date(entry.date)));
            takenUnpaidSickDaysChartValues.push(entry.amount);
        })

        state.takenUnpaidSickDaysChartDates = takenUnpaidSickDaysChartDates;
        state.takenUnpaidSickDaysChartValues = takenUnpaidSickDaysChartValues;
    },

    setTodayAttendanceStatistics(state, payload) {
        state.todayPaidDaysOff = payload['paidDaysOff'][0].amount;
        state.todayUnpaidDaysOff = payload['unpaidDaysOff'][0].amount;
        state.todayPaidSickDays = payload['paidSickDays'][0].amount;
        state.todayUnpaidSickDays = payload['unpaidSickDays'][0].amount;
    },

    setCompanyEmployeesAmount(state, payload) {
        state.companyEmployeesAmount = payload;
    },

    setCompanyOnlineEmployeesAmount(state, payload) {
        state.employeesOnlineAmount = payload;
    },

    setCompanyFixedAssetsAmount(state, payload) {
        state.fixedAssetsAmount = payload;
    },

    setCompanyProjectsAmount(state, payload) {
        state.projectsAmount = payload;
    }
}