export default {
    workingHours(state){
        return state.project_working_hours;
    },

    hasWorkingHours(state) {
        return state.project_working_hours && state.project_working_hours.length > 0;
    },

    selectedProject(state){
        return state.selectedProject;
    }
}