export default {
    companyEmployeesAmount: (state) => state.companyEmployeesAmount,
    employeesOnlineAmount: (state) => state.employeesOnlineAmount,
    fixedAssetsAmount: (state) => state.fixedAssetsAmount,
    projectsAmount: (state) => state.projectsAmount,
    todayPaidDaysOff: (state) => state.todayPaidDaysOff,
    todayUnpaidDaysOff: (state) => state.todayUnpaidDaysOff,
    todayPaidSickDays: (state) => state.todayPaidSickDays,
    todayUnpaidSickDays: (state) => state.todayUnpaidSickDays,
    takenPaidDaysOffChartDates: (state) => state.takenPaidDaysOffChartDates,
    takenPaidDaysOffChartValues: (state) => state.takenPaidDaysOffChartValues,
    takenUnpaidDaysOffChartDates: (state) => state.takenUnpaidDaysOffChartDates,
    takenUnpaidDaysOffChartValues: (state) => state.takenUnpaidDaysOffChartValues,
    takenPaidSickDaysChartDates: (state) => state.takenPaidSickDaysChartDates,
    takenPaidSickDaysChartValues: (state) => state.takenPaidSickDaysChartValues,
    takenUnpaidSickDaysChartDates: (state) => state.takenUnpaidSickDaysChartDates,
    takenUnpaidSickDaysChartValues: (state) => state.takenUnpaidSickDaysChartValues,

}