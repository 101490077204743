export default {
    setShowMenu(state, payload){
        state.showMenu = payload;
    },
    setCurrentRoute(state, payload){
        state.currentRoute = payload;
    },
    resetShowMenu(state){
        state.showMenu = false;
    },
    resetCurrentRoute(state){
        state.currentRoute = null;
    }
}