export default {
    setSettings(state, payload) {
        state.settings = payload;
    },
    setTheme(state, payload) {
        state.theme = payload;
    },
    updateSettings(state, payload) {
        state.settings[payload.name] = payload.value;
    },

    setLanguage(state, payload){
        console.log(payload);
        state.settings["USER.PREF_LANG"] = {propagation: undefined, value: payload.value};
        localStorage.setItem("language", JSON.stringify(payload.value));
    },

    // ADMINISTRATIVE
    addUsersResponsibleForNewEmployeesApproval(state, payload) {
        state.usersResponsibleForNewEmployeesApproval = payload;
    },
    addUserResponsibleForNewEmployeesApproval(state, payload) {
        state.usersResponsibleForNewEmployeesApproval.push(payload);
    },
    addUsersAllowedToAssignManager(state, payload) {
        state.usersAllowedToAssignManager = payload;
    },
    addUsersAllowedToRemoveManagerAssignment(state, payload) {
        state.usersAllowedToRemoveManagerAssignment = payload;
    },
    addUserAllowedToAssignManager(state, payload) {
        state.usersAllowedToAssignManager.push(payload);
    },
    addUserAllowedToRemoveManagerAssignment(state, payload) {
        state.usersAllowedToRemoveManagerAssignment.push(payload);
    },
    deleteUsersResponsibleForNewEmployeesApproval(state, payload) {
        const index = state.usersResponsibleForNewEmployeesApproval.findIndex(item => item.uuid === payload);
        if (index !== -1) {
            state.usersResponsibleForNewEmployeesApproval.splice(index, 1);
        }
    },
    deleteUserAllowedToAssignManager(state, payload) {
        const index = state.usersAllowedToAssignManager.findIndex(item => item.uuid === payload.uuid);
        if (index !== -1) {
            state.usersAllowedToAssignManager.splice(index, 1);
        }
    },
    deleteUserAllowedToRemoveManagerAssignment(state, payload) {
        const index = state.usersAllowedToRemoveManagerAssignment.findIndex(item => item.uuid === payload.uuid);
        if (index !== -1) {
            state.usersAllowedToRemoveManagerAssignment.splice(index, 1);
        }
    },

    //ADD UPDATES
    loadUsersAllowedAddUpdates(state, payload) {
        state.usersAllowedAddUpdates = payload;
    },
    addUsersAllowedAddUpdates(state, payload) {
        state.usersAllowedAddUpdates.push(payload);
        state.settings["USER.COMPANY_NEWS.ADD"] = true;
    },
    deleteUsersAllowedAddUpdates(state, payload) {
        const index = state.usersAllowedAddUpdates.findIndex(item => item.uuid === payload);
        if (index !== -1) {
            state.usersAllowedAddUpdates.splice(index, 1);
        }
        state.settings["USER.COMPANY_NEWS.ADD"] = false;
    },
    // EDIT UPDATES
    loadUsersAllowedEditUpdates(state, payload) {
        state.usersAllowedEditUpdates = payload;
    },
    addUsersAllowedEditUpdates(state, payload) {
        state.usersAllowedEditUpdates.push(payload);
    },
    deleteUsersAllowedEditUpdates(state, payload) {
        const index = state.usersAllowedEditUpdates.findIndex(item => item.uuid === payload);
        if (index !== -1) {
            state.usersAllowedEditUpdates.splice(index, 1);
        }
    },
    // DELETE UPDATES
    loadUsersAllowedDeleteUpdates(state, payload) {
        state.usersAllowedDeleteUpdates = payload;
    },
    addUsersAllowedDeleteUpdates(state, payload) {
        state.usersAllowedDeleteUpdates.push(payload);
    },
    deleteUsersAllowedDeleteUpdates(state, payload) {
        const index = state.usersAllowedDeleteUpdates.findIndex(item => item.uuid === payload);
        if (index !== -1) {
            state.usersAllowedDeleteUpdates.splice(index, 1);
        }
    },
    //Company Employees EDIT COMPANY EMPLOYEES INFO
    loadUsersAllowedEditAllCompanyEmployeesInfo(state, payload) {
        state.usersAllowedEditAllCompanyEmployeesInfo = payload;
    },
    addUsersAllowedEditAllCompanyEmployeesInfo(state, payload) {
        state.usersAllowedEditAllCompanyEmployeesInfo.push(payload);
    },
    deleteUsersAllowedEditAllCompanyEmployeesInfo(state, payload) {
        const index = state.usersAllowedEditAllCompanyEmployeesInfo.findIndex(item => item.uuid === payload);
        if (index !== -1) {
            state.usersAllowedEditAllCompanyEmployeesInfo.splice(index, 1);
        }
    },
    // EMPLOYEES ALLWED TO DELETE COMPANY EMPLOYEES
    loadUsersAllowedDeleteCompanyEmployees(state, payload) {
        state.usersAllowedDeleteCompanyEmployees = payload;
    },
    addUsersAllowedDeleteCompanyEmployees(state, payload) {
        state.usersAllowedDeleteCompanyEmployees.push(payload);
    },
    deleteUsersAllowedDeleteCompanyEmployees(state, payload) {
        const index = state.usersAllowedDeleteCompanyEmployees.findIndex(item => item.uuid === payload);
        if (index !== -1) {
            state.usersAllowedDeleteCompanyEmployees.splice(index, 1);
        }
    },
    // ALLOWED TO ADD EVENTS
    loadUsersAllowedAddEvents(state, payload) {
        state.usersAllowedAddEvents = payload;
    },
    addUsersAllowedAddEvents(state, payload) {
        state.usersAllowedAddEvents.push(payload);
    },
    deleteUsersAllowedAddEvents(state, payload) {
        const index = state.usersAllowedAddEvents.findIndex(item => item.uuid === payload);
        if (index !== -1) {
            state.usersAllowedAddEvents.splice(index, 1);
        }
    },
    // ALLOWED TO EDIT EVENTS
    loadUsersAllowedEditEvents(state, payload) {
        state.usersAllowedEditEvents = payload;
    },
    addUsersAllowedAEditEvents(state, payload) {
        state.usersAllowedEditEvents.push(payload);
    },
    deleteUsersAllowedEditEvents(state, payload) {
        const index = state.usersAllowedEditEvents.findIndex(item => item.uuid === payload);
        if (index !== -1) {
            state.usersAllowedEditEvents.splice(index, 1);
        }
    },
    //ALLOWED TO DELETE EVENTS
    loadUsersAllowedDeleteEvents(state, payload) {
        state.usersAllowedDeleteEvents = payload;
    },
    addUsersAllowedADeleteEvents(state, payload) {
        state.usersAllowedDeleteEvents.push(payload);
    },
    deleteUsersAllowedDeleteEvents(state, payload) {
        const index = state.usersAllowedDeleteEvents.findIndex(item => item.uuid === payload);
        if (index !== -1) {
            state.usersAllowedDeleteEvents.splice(index, 1);
        }
    },
    //ALLOWED TO SEE ALL COMPANY EMPLOYEES TIMESHEETS
    loadUsersAllowedToSeeAllCompanyEmployeesTimesheets(state, payload) {
        state.usersAllowedToSeeAllCompanyEmployeesTimesheets = payload;
    },
    addUsersAllowedToSeeAllCompanyEmployeesTimesheets(state, payload) {
        state.usersAllowedToSeeAllCompanyEmployeesTimesheets.push(payload);
    },
    deleteUsersAllowedToSeeAllCompanyEmployeesTimesheets(state, payload) {
        const index = state.usersAllowedToSeeAllCompanyEmployeesTimesheets.findIndex(item => item.uuid === payload);
        if (index !== -1) {
            state.usersAllowedToSeeAllCompanyEmployeesTimesheets.splice(index, 1);
        }
    },
    // ALLOWED TO APPROVE ALL COMPANY EMPLOYEES TIMESHEETS
    loadUsersAllowedToApproveAllCompanyEmployeesTimesheets(state, payload) {
        state.usersAllowedToApproveAllCompanyEmployeesTimeSheets = payload;
    },
    addUsersAllowedToApproveAllCompanyEmployeesTimesheets(state, payload) {
        state.usersAllowedToApproveAllCompanyEmployeesTimeSheets.push(payload);
    },
    deleteUsersAllowedToApproveAllCompanyEmployeesTimesheets(state, payload) {
        const index = state.usersAllowedToApproveAllCompanyEmployeesTimeSheets.findIndex(item => item.uuid === payload);
        if (index !== -1) {
            state.usersAllowedToApproveAllCompanyEmployeesTimeSheets.splice(index, 1);
        }
    },
    // ALLOWED TO REJECT TIMESHEETS
    loadUsersAllowedToRejectAllCompanyEmployeesTimesheets(state, payload) {
        state.usersAllowedToRejectAllCompanyEmployeesTimeSheets = payload;
    },
    addUsersAllowedToRejectAllCompanyEmployeesTimesheets(state, payload) {
        state.usersAllowedToRejectAllCompanyEmployeesTimeSheets.push(payload);
    },
    deleteUsersAllowedToRejectAllCompanyEmployeesTimesheets(state, payload) {
        const index = state.usersAllowedToRejectAllCompanyEmployeesTimeSheets.findIndex(item => item.uuid === payload);
        if (index !== -1) {
            state.usersAllowedToRejectAllCompanyEmployeesTimeSheets.splice(index, 1);
        }
    },
    // ALLOWED TO MODIFY TIMESHEETS
    loadUsersAllowedToModifyAllCompanyEmployeesTimesheets(state, payload) {
        state.usersAllowedToModifyAllCompanyEmployeesTimeSheets = payload;
    },
    addUsersAllowedToModifyAllCompanyEmployeesTimesheets(state, payload) {
        console.log("MUTATION");
        console.log(payload);
        state.usersAllowedToModifyAllCompanyEmployeesTimeSheets.push(payload);
    },
    deleteUsersAllowedToModifyAllCompanyEmployeesTimesheets(state, payload) {
        const index = state.usersAllowedToModifyAllCompanyEmployeesTimeSheets.findIndex(item => item.uuid === payload);
        if (index !== -1) {
            state.usersAllowedToModifyAllCompanyEmployeesTimeSheets.splice(index, 1);
        }
    },
}