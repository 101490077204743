export default {
    tasks(state){
        return state.tasks
    },
    hasTasks(state){
        return state.tasks && state.tasks.length > 0;
    },
    getFirstTask(state){
        return state.tasks ? state.tasks[0]: null;
    }
}